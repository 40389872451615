import React, { useEffect } from 'react';
import axios from 'axios';

import Cross from '../../Resources/Icons/x.png';
import { toast } from 'react-toastify';
import SearchableDropdown from '../../Components/SearchableDropdown';

const JobModel = ({ showModal, setShowModal, setUpdate, update, formData, setFormData, isEdit, refreshJobs }) => {

    const handleAddOrEditJob = async () => {
        try {
            if (isEdit) {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/job/editjob/${formData._id}`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/job/addjob`, formData);
            }
            setUpdate(!update);
            refreshJobs();
        } catch (error) {
            console.error('Error adding or editing job', error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (!showModal) {
            setFormData({
                company: '',
                businessNumber: '',
                category: '',
                position: '',
                location: '',
                period: '',
                qualification: '',
                amenities: '',
                permit: '',
                overtime: '',
                available: true,
                salary: '',
                vacancies: ''
            });
        }
    }, [showModal]);

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center py-10 overflow-x-hidden overflow-y-auto outline-none focus:outline-none sm:py-0">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative max-w-screen-sm mx-auto my-6 min-w-fit m-screen">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-10 p-5 border-b border-gray-200 border-solid rounded-t sm:px-10">
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => setShowModal(false)} />
                                <h className='text-xl font-bold sm:text-3xl'>{isEdit ? 'Edit Job Position' : 'Create Job Position'}</h>
                            </div>
                            <div className='flex flex-col sm:flex-row gap-10 px-10 sm:px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20'>
                                <div className='flex flex-col gap-3'>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Company</label>
                                        <input type='text' name='company' value={formData.company} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Business Number</label>
                                        <input type='text' name='businessNumber' value={formData.businessNumber} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Job Category</label>
                                        <input type='text' name='category' value={formData.category} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Job Position</label>
                                        <SearchableDropdown
                                            formData={formData}
                                            setFormData={setFormData}
                                            label="name"
                                            id="id"
                                        />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Job Location</label>
                                        <input type='text' name='location' value={formData.location} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Vacancies</label>
                                        <input type='number' name='vacancies' value={formData.vacancies} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Period of Contract</label>
                                        <input type='text' name='period' value={formData.period} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Qualification</label>
                                        <input type='text' name='qualification' value={formData.qualification} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Food and Accommodation</label>
                                        <input type='text' name='amenities' value={formData.amenities} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Resident permit</label>
                                        <input type='text' name='permit' value={formData.permit} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Overtime</label>
                                        <input type='text' name='overtime' value={formData.overtime} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[250px] sm:w-[200px] md:w-[250px] lg:w-[300px] text-right'>
                                        <label>Salary</label>
                                        <input type='text' name='salary' value={formData.salary} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between max-w-full p-4 border-t border-solid rounded-b border-Gray-200 sm:max-w-full">
                                <button
                                    className="px-5 sm:px-10 md:px-20 py-4 mb-1 mr-1 text-sm font-bold text-white bg-[#CA9731] bg-opacity-[75%] rounded-full uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                    type="button"
                                    onClick={() => {
                                        if (!formData.company || !formData.amenities || !formData.category || !formData.location || !formData.overtime || !formData.period || !formData.permit || !formData.position || !formData.qualification || !formData.salary || !formData.vacancies) {
                                            toast.error('Fill all the Fields')
                                        } else {
                                            setShowModal(false); handleAddOrEditJob()
                                        }
                                    }}
                                >
                                    {isEdit ? 'Save Changes' : 'Add Job Position'}
                                </button>
                                <button
                                    className="px-5 sm:px-10 md:px-20 py-4 mb-1 mr-1 text-sm font-bold text-gray-400 bg-[#F8FAFB] rounded-full uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default JobModel;
