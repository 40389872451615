import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RecruiterContext from '../../Components/RecruterContext';
import NavBar from '../../Components/NavBar';
import Search from '../../Resources/Icons/search.png';
import axios from 'axios';
import Profile from '../../Resources/Ellipse 16.png';
import RecommendForm from '../../Components/Notification/RecommendForm';
import RequestModal from '../../Components/Notification/RequestForm';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const Notifications = () => {
    const navigate = useNavigate();
    const [update, setUpdate] = useState(false);
    const [downloadingDocs, setDownloadingDocs] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const { sideBarStatus } = useContext(RecruiterContext);
    const [selectedNotification, setSelectedNotification] = useState();
    const [recommendModal, setRecommendModal] = useState(false);
    const [requestModal, setRequestModal] = useState(false);

    const markAsRead = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/notification/markasread`);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        markAsRead();
    }, []);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        const userRole = sessionStorage.getItem('role');
        if (!token) {
            navigate('/login');
        } else if (userRole !== 'admin') {
            navigate('/recruters');
        }
    }, [navigate]);

    function convertToBelgradeTime(dateStr) {
        return new Date(dateStr).toLocaleString("en-US", { timeZone: "Europe/Belgrade" });
    }

    function calculateTimeDifference(startDateStr, endDateStr) {
        const startDate = new Date(convertToBelgradeTime(startDateStr));
        const endDate = new Date(convertToBelgradeTime(endDateStr));
        const difference = Math.abs(endDate - startDate) / 1000; // Difference in seconds

        if (difference < 60) {
            return difference < 1 ? "Just now" : `${Math.floor(difference)} seconds ago`;
        } else if (difference < 3600) {
            const minutes = Math.floor(difference / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else if (difference < 86400) {
            const hours = Math.floor(difference / 3600);
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (difference < 2592000) { // 30 days
            const days = Math.floor(difference / 86400);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (difference < 31536000) { // 1 year
            const months = Math.floor(difference / 2592000);
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else {
            const years = Math.floor(difference / 31536000);
            return `${years} year${years > 1 ? 's' : ''} ago`;
        }
    }

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/notification/getallnotifications`);
            const currentTime = new Date().toISOString();
            const processedNotifications = response.data.map((notification) => ({
                ...notification,
                creationDate: convertToBelgradeTime(notification.creationDate),
                time: calculateTimeDifference(notification.creationDate, currentTime),
            }));
            setNotifications(processedNotifications);
            setFilteredNotifications(processedNotifications);
        } catch (error) {
            console.error('Error fetching notifications: ', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, [update]);

    useEffect(() => {
        const timer = setInterval(() => {
            const currentTime = new Date().toISOString();
            const updatedNotifications = notifications.map(notification => ({
                ...notification,
                time: calculateTimeDifference(notification.creationDate, currentTime)
            }));
            setNotifications(updatedNotifications);
            setFilteredNotifications(updatedNotifications);
        }, 60000); // Update every minute

        return () => clearInterval(timer);
    }, [notifications]);

    useEffect(() => {
        if (searchQuery) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            setFilteredNotifications(notifications.filter(notification => 
                notification.description.toLowerCase().includes(lowerCaseQuery)
            ));
        } else {
            setFilteredNotifications(notifications);
        }
    }, [searchQuery, notifications]);

    const handleAcceptClick = async (_id) => {
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/notification/updatestatus/${_id}`, {
                params: { status: 'accepted' }
            });
            setUpdate(!update);
        } catch (error) {
            console.error('Error updating status: ', error.message);
        }
    };

    const handleRejectClick = async (_id) => {
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/notification/updatestatus/${_id}`, {
                params: { status: 'rejected' }
            });
            setUpdate(!update);
        } catch (error) {
            console.error('Error updating status: ', error.message);
        }
    };

    const handleTransfer = async (notification) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/job/transferjobnotification`, { notification });
        } catch (error) {
            console.error('Error transferring job: ', error.message);
        }
    };

    const handleJob = async (notification) => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/job/updatestatus/${notification.job}`);
        } catch (error) {
            console.error('Error updating job status: ', error.message);
        }
    };

    const downloadDocument = async (doc) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/download-recruiter-doc/${doc}`);
            const blob = await response.blob();
            return blob;
        } catch (error) {
            console.error('Error downloading document:', error);
            throw error;
        }
    };

    const downloadCV = async (originalFilename) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/download-cv/${originalFilename}_CV.pdf`);
            const blob = await response.blob();
            return blob;
        } catch (error) {
            console.error('Error downloading CV:', error);
            throw error;
        }
    };

    const handleDocDownload = async (recruiterId) => {
        if (downloadingDocs[recruiterId]) {
            return; // Do nothing if already downloading
        }

        setDownloadingDocs(prev => ({ ...prev, [recruiterId]: true }));

        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recruiter/getrecruiter/${recruiterId}`);
            const docs = response.data.docs;
            const email = response.data.email;
            const firstName = response.data.firstName;

            const zip = new JSZip();
            const folder = zip.folder(firstName);

            // Add all documents to the zip
            for (const doc of docs) {
                const blob = await downloadDocument(doc);
                folder.file(doc.split('-_-')[1], blob);
            }

            // Add CV to the zip
            const cvBlob = await downloadCV(email);
            folder.file(`${firstName}_CV.pdf`, cvBlob);

            // Generate and save the zip
            const content = await zip.generateAsync({ type: 'blob' });
            saveAs(content, `${firstName}_documents.zip`);
        } catch (error) {
            console.log('Error downloading documents:', error.message);
        } finally {
            setDownloadingDocs(prev => ({ ...prev, [recruiterId]: false }));
        }
    };

    const handleNotificationClick = (notification) => {
        setSelectedNotification(notification);
        if (notification.recruiter == null) {
            setRecommendModal(true);
        } else {
            setRequestModal(true);
        }
    };

    return (
        <div className='w-screen h-screen bg-gray-50'>
            <div className={`h-full absolute top-24 sm:top-0 right-0 flex flex-col items-center pt-10 transition-all duration-500 ${sideBarStatus ? 'w-screen sm:w-[95%] md:w-[95%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%]' : 'w-screen sm:w-[95%] md:w-[95%] xl:w-[95%]'}`}>
                <div className='flex sm:w-[80%] lg:w-[80%] w-[95%] flex-row items-center justify-between gap-10'>
                    <h className='text-3xl font-bold'>Notifications</h>
                    <div className='flex flex-row items-center bg-gray-100 border-none border-gray-300 w-[50%] rounded-lg p-2'>
                        <input 
                            type='text' 
                            className='w-full h-full px-2 bg-transparent border-none rounded-lg outline-none' 
                            placeholder='Search...' 
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <img src={Search} className='w-6 h-6' alt='Search' />
                    </div>
                    <div className='hidden sm:block'>
                        <NavBar />
                    </div>
                </div>
                <div className='sm:w-[80%] lg:w-[80%] w-[95%] mt-10 sm:mt-32 h-screen flex flex-col px-10'>
                    <RequestModal 
                        requestModal={requestModal}
                        setRequestModal={setRequestModal}
                        notification={selectedNotification}
                    />
                    <RecommendForm
                        showModal={recommendModal}
                        setShowModal={setRecommendModal}
                        notification={selectedNotification}
                    />
                    <div className='flex flex-col w-full gap-3 mt-10 bg-white max-h-[60vh] overflow-auto rounded-lg p-5'>
                        {filteredNotifications.length > 0 ?  (
                            filteredNotifications.map((notification, index) => (
                                <div key={index} className={`rounded-lg px-5 py-2 min-w-full flex flex-row justify-between gap-10 hover:bg-[#FDCF8B] hover:bg-opacity-30 transition-all duration-300 cursor-pointer 
                                    ${notification.read ? 'border-gray-200' : 'bg-[#FDCF8B] bg-opacity-30'}
                                `}>
                                    <div className='flex flex-row gap-5'>
                                        <img src={Profile} className='w-12 h-12' alt='' />
                                        <div className='flex flex-col'>
                                            <span>{notification.description}</span>
                                            <span className='text-gray-500'>{notification.time}</span>
                                        </div>
                                    </div>
                                    <div className={`items-center justify-center h-full gap-2 align-middle ${notification.status !== '' ? 'hidden' : 'flex'}`}>
                                        <button 
                                            className={`p-1 px-5 font-bold text-white rounded-lg transition-all duration-300 
                                                ${notification.recruiter == null ? 'hidden' : 'block'}
                                                ${notification.recruiter && notification.recruiter._id && downloadingDocs[notification.recruiter._id] 
                                                    ? 'bg-green-500 cursor-not-allowed' 
                                                    : 'bg-gray-300 hover:bg-green-400'}`}
                                            onClick={() => handleDocDownload(notification.recruiter._id)}
                                            disabled={notification.recruiter && notification.recruiter._id && downloadingDocs[notification.recruiter._id]}
                                        >
                                            {notification.recruiter && notification.recruiter._id && downloadingDocs[notification.recruiter._id] ? 'Downloading...' : 'Download'}
                                        </button>
                                        <button className={`p-1 px-5 font-bold text-white bg-gray-300 rounded-lg hover:bg-green-400 transition-all duration-300`}
                                        onClick={() => {setSelectedNotification(notification); handleNotificationClick(notification)}}
                                        >View</button>
                                        <button className='p-1 px-5 font-bold text-white bg-[#CA9731] rounded-lg'
                                        onClick={() => handleRejectClick(notification._id)}
                                        >Reject</button>
                                        <button onClick={() => {
                                            handleAcceptClick(notification._id);
                                            if(notification.recruiter == null) {
                                                handleJob(notification);
                                            } else {
                                                handleTransfer(notification);
                                            }
                                        }} className='p-1 px-5 font-bold text-white bg-green-500 rounded-lg'>Accept</button>
                                    </div>
                                    <div className={`items-center justify-center h-full gap-2 align-middle ${notification.status !== '' ? 'flex' : 'hidden'}`}>
                                        <button className={`p-2 px-10 font-bold text-white rounded-lg 
                                            ${notification.status === 'rejected' ? 'bg-red-300' : 'bg-green-300'}`}
                                        >
                                            {notification.status}
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No notifications</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
