import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';

import Cross from '../../Resources/Icons/x.png'

const RecommendForm = ({ showModal, setShowModal, notification }) => {

    const [job, setJob] = useState({
        company: '',
        category: '',
        position: '',
        location: '',
        period: '',
        qualification: '',
        amenities: '',
        permit: '',
        overtime: '',
    });
    const [agent, setAgent] = useState({
        firstName: '',
        lastName: '',
        email: '',
        id: '',
        mobile: '',
        image: '',
        agentType: '',
    });

    const fetchAgent = async () => {
        try {
            const email = sessionStorage.getItem('user');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/agent/getagent/${notification.agent}`);
            setAgent(response.data);
        } catch (error) {
            console.error('Error fetching the Agents: ', error.message);
        }
    }

    const fetchJob =  async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/job/getjob/${notification.job}`);
            setJob(response.data);
        } catch (error) {
            console.error('Error fetching the Agents: ', error.message);
        }
    }

    useEffect(() => {
        fetchAgent();
        fetchJob();
    }, [showModal]);

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center py-10 overflow-x-hidden overflow-y-auto outline-none focus:outline-none sm:py-0">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative max-w-screen-sm mx-auto my-6 min-w-fit m-screen">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-10 p-5 border-b border-gray-200 border-solid rounded-t sm:px-10">

                                <h className='bg-[#FDCF8B] px-5 py-2 rounded-xl font-bold'>Recommend Job Position</h>
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => setShowModal(false)} />
                            </div>
                            <div className='flex flex-col sm:flex-col gap-10 px-10 sm:px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 max-h-[70vh] overflow-auto'>
                                <div className='flex flex-row'>
                                    <div className='w-[200px] h-full items-start justify-start'>
                                        <labal>Job Details</labal>
                                    </div>
                                    <div className='flex flex-col gap-3 p-10 bg-white shadow-inner rounded-xl'>
                                        <div className='flex flex-row justify-between w-full gap-5'>
                                            <div className='flex flex-col gap-1'>
                                                <labal>Company Name</labal>
                                                <input type='text' readOnly
                                                    name='companyName'
                                                    value={job?.company}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <labal>Company Name</labal>
                                                <input type='text' readOnly
                                                    name='companyName'
                                                    value={job?.company}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                        </div>
                                        <div className='flex flex-row justify-between w-full gap-5'>
                                            <div className='flex flex-col gap-1'>
                                                <labal>Business Unique Number</labal>
                                                <input
                                                    type='text' readOnly
                                                    name='businessUniqueNumber'
                                                    value={job?.businessNumber}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <labal>Address</labal>
                                                <input type='text' readOnly
                                                    name='address'
                                                    value={job?.location}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row'>
                                    <div className='w-[200px] h-full items-start justify-start'>
                                        <labal>Agent Details</labal>
                                    </div>
                                    <div className='flex flex-col gap-3 p-10 bg-white shadow-inner rounded-xl'>
                                        <div className='flex flex-row justify-between w-full gap-5'>
                                            <div className='flex flex-col gap-1'>
                                                <labal>First Name</labal>
                                                <input type='text' readOnly
                                                    value={agent?.firstName}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <labal>Last Name</labal>
                                                <input type='text' readOnly
                                                    value={agent.lastName}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                        </div>
                                        <div className='flex flex-row justify-between w-full gap-5'>
                                            <div className='flex flex-col gap-1'>
                                                <labal>Email</labal>
                                                <input type='text' readOnly
                                                    name='fromDate'
                                                    value={agent.email}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <labal>Phone</labal>
                                                <input type='text' readOnly
                                                    name='toDate'
                                                    value={agent.mobile}
                                                    className='outline-none border-[1px] border-gray-300 rounded-xl p-2 w-[300px]' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default RecommendForm;
