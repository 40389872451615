import React, { useState } from 'react';

import Cross from '../Resources/Icons/x.png';
import Profile from '../Resources/Icons/profile.png';

const Modal = ({ showModal, setShowModal, setRecruiter, recruiters }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredRecruters = recruiters.filter((recruiter) => {
        const empty = searchQuery === '';
        const agentFNameMatch = recruiter.firstName.toLowerCase().includes(searchQuery.toLowerCase());
        const agentLNameMatch = recruiter.lastName.toLowerCase().includes(searchQuery.toLowerCase());

        return agentFNameMatch || agentLNameMatch || empty;
    });

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6 max-w-3xl md:max-w-4xl lg:max-w-5xl">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-4 p-5 px-6 border-b border-solid rounded-t border-blueGray-200">
                                <img src={Cross} className='w-8 h-8 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => setShowModal(false)} />
                                <h3 className="text-lg font-bold text-black">Candidates List</h3>
                            </div>
                            <div className={`flex items-center justify-center py-3 px-6 ${recruiters.length < 1 ? 'hidden' : 'flex'}`}>
                                <input
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className='border-2 border-gray-300 p-2 rounded w-full max-w-md'
                                    placeholder='Search...'
                                />
                            </div>
                            <div className='px-6 pt-5 pb-10 bg-[#F8FAFB] max-h-[60vh] overflow-y-auto'>
                                {
                                    filteredRecruters.length !== 0 ? filteredRecruters.map((recruiter) => (
                                        <div key={recruiter.id} onClick={() => { setRecruiter(recruiter); setShowModal(false) }} className='flex items-center gap-4 py-2 px-4 text-left border-b-[1px] border-gray-300 rounded hover:bg-gray-300 transition-all duration-300 cursor-pointer'>
                                            <span className='flex-1'>{recruiter.email}</span>
                                            <span className='hidden md:block flex-1'>{recruiter.id}</span>
                                            <div className='flex items-center gap-3'>
                                                <span>{recruiter.firstName} {recruiter.lastName}</span>
                                                <img src={recruiter.profileImage ? recruiter.profileImage : Profile} className='w-8 h-8 rounded-full' alt="Profile" />
                                            </div>
                                        </div>
                                    )) : <div className='text-center'>No Active Candidates to show.</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Modal;
