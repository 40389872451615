import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cross from '../../Resources/Icons/x.png';
import Upload from '../../Resources/Icons/Upload.png';
import Nationalities from '../../Resources/Nationalities.json';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import Logo from '../../Resources/logo.jpg';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Modal = ({ newDocModelStep, setNewDocModelStep, newDocFormData, setNewDocFormData,paymentMethod, setPaymentMethod, isEdit, update, setUpdate, methodOfPayment }) => {

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        // Check if all required fields are filled
        const requiredFieldsFilled = newDocFormData.firstName && newDocFormData.lastName && newDocFormData.passport && newDocFormData.serviceFee && methodOfPayment;
        setIsFormValid(requiredFieldsFilled);
    }, [newDocFormData, methodOfPayment]);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function generateRandom5DigitString() {
        const randomNumber = Math.floor(Math.random() * 90000) + 10000;
        const randomString = randomNumber.toString();
        return randomString;
    }

    const generateInvoicePdf = async () => {

        const doc = new jsPDF();

        const borderWidth = 5; // Border width
        const padding = 10; // Padding within the border
        const contentWidth = doc.internal.pageSize.getWidth() - 2 * padding;
        const tableWidth = 60; // Adjusted table width

        doc.setDrawColor(0); // Black border color
        doc.rect(padding, padding, contentWidth, doc.internal.pageSize.getHeight() - 2 * padding, 'D'); // Draw border

        const textBoxX = padding + contentWidth - 35; // X position for text box
        const textBoxY = padding + borderWidth - 10; // Y position for text box
        const textBoxWidth = 100; // Width of the text box
        const textBoxHeight = 15; // Height of the text box
        doc.setFillColor(0, 0, 0); // Black background color
        doc.rect(textBoxX, textBoxY, textBoxWidth, textBoxHeight, 'F'); // Draw filled rectangle
        doc.setTextColor(255, 165, 0); // Orange text color
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(19);
        doc.text('INVOICE', textBoxX + 5, textBoxY + 10); // Adjust text position within the box
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0, 0, 0); // Black text color

        const logoImg = new Image();
        logoImg.src = Logo; // Provide path to your logo image
        await sleep(1000); // Wait for the image to load (simulating async behavior)
        const logoWidth = 40; // Increased width of logo
        const logoHeight = Math.round((logoWidth * logoImg.height) / logoImg.width);
        const logoX = padding + borderWidth; // X position of logo (inside the border)
        const logoY = padding + borderWidth; // Y position of logo (inside the border)
        doc.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);

        const invoiceNo = 'INV-' + generateRandom5DigitString();
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0'); // Day (with leading zero if needed)
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month (adding 1 because January is 0)
        const year = currentDate.getFullYear(); // Full year
        const date = `${day}/${month}/${year}`; // Form the desired date string in 'DD/MM/YYYY' format

        const invoiceInfoX = padding + contentWidth - 50; // X position at top right
        const invoiceInfoY = padding + borderWidth + 40; // Y position (inside the border)
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text(`Invoice#:`, invoiceInfoX, invoiceInfoY);
        doc.setFont('helvetica', 'normal');
        doc.text(`${invoiceNo}`, invoiceInfoX + 25, invoiceInfoY);

        doc.setFont('helvetica', 'bold');
        doc.text(`Date:`, invoiceInfoX, invoiceInfoY + 7);
        doc.setFont('helvetica', 'normal');
        doc.text(`${date}`, invoiceInfoX + 25, invoiceInfoY + 7);

        const invoiceToY = padding + borderWidth + 50; // Y position below border and top info
        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text('Invoice To', padding + borderWidth, invoiceToY);
        doc.setFont('helvetica', 'normal');
        let personName = newDocFormData.firstName;
        if(newDocFormData.middleName){
            personName+= " " + newDocFormData.middleName 
        }
        personName+= " " + newDocFormData.lastName

        const personEmail = newDocFormData.email;
        const personPhone = newDocFormData.phone;
        const serviceFee = newDocFormData.serviceFee

        doc.setFontSize(10);
        doc.text(`${personName}`, padding + borderWidth, invoiceToY + 8);
        doc.setFontSize(8);
        doc.text(`${personEmail}`, padding + borderWidth, invoiceToY + 8 + 8);
        doc.text(`${personPhone}`, padding + borderWidth, invoiceToY + 8 + 8 + 5);


        const tableHeaders = ['SL', 'ITEM', 'PRICE', 'QTY', 'TOTAL'];
        const tableData = [
            ['1', 'DOCUMENT EXPERTISE', serviceFee, '1', serviceFee],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
        ];

        const tableY = invoiceToY + 40; // Y position below Invoice To section

        doc.autoTable({
            startY: tableY,
            head: [tableHeaders],
            body: tableData,
            theme: 'striped',
            styles: {
                lineWidth: 0.5,
                fontSize: 8,
                cellPadding: 4,
                valign: 'middle',
                halign: 'center',
                tableWidth: tableWidth, // Set the table width
            },
            headStyles: {
                fillColor: [0, 0, 0], // Black background for header
                textColor: [255, 165, 0], // Orange text color for header
                fontSize: 10,
                fontStyle: 'bold',
                cellPadding: 4,
            },
            columnStyles: {
                0: { halign: 'center' }, // SL column
                1: { cellWidth: 'center' }, // ITEM column (allow wrapping)
                2: { halign: 'center' }, // PRICE column
                3: { halign: 'center' }, // QTY column
                4: { halign: 'center' }, // TOTAL column
            },

            
        });

        const subtotalX = padding + contentWidth - 70; // X position at top right
        const subtotalY = tableY + (tableData.length + 1) * 15 - 10; // Y position below table
        doc.setFontSize(10);
        doc.text('Sub Total:', subtotalX, subtotalY);
        doc.text(`${serviceFee}`, subtotalX + 50, subtotalY);

        const totalX = padding + contentWidth - 70; // X position at top right
        const totalY = doc.internal.pageSize.getHeight() - padding - borderWidth - 55; // Y position at bottom right
        doc.setFillColor(255, 165, 0); // Orange fill color
        doc.rect(totalX - 5, totalY - 5, 70, 10, 'F'); // Draw filled rectangle for background
        doc.setFontSize(11);
        doc.setFont('helvetica', 'bold');
        doc.text('Total:', totalX, totalY + 2);
        doc.text(`${serviceFee}`, totalX + 50, totalY + 2);
        doc.setFont('helvetica', 'normal');

        doc.setFontSize(8);
        const termsX = padding + borderWidth; // X position at top left
        const termsY = subtotalY + 14; // Y position below Sub Total
        doc.setFont('helvetica', 'bold');

        doc.setFont('helvetica', 'normal');


        // Add Payment Method at the bottom left
        const paymentMethodX = padding + borderWidth; // X position at bottom left
        const paymentMethodY = doc.internal.pageSize.getHeight() - padding - borderWidth - 40; // Y position at bottom left
        doc.setFont('helvetica', 'bold');

        doc.text('Payment Method', paymentMethodX, totalY + 2);
        doc.text('Payment Method', paymentMethodX, totalY + 2);
        doc.setFont('helvetica', 'normal');
        doc.text(methodOfPayment, paymentMethodX, totalY + 10);

        doc.text(methodOfPayment, paymentMethodX, totalY + 10);


        doc.addPage();

        doc.addImage(logoImg, 'JPEG', logoX, logoY, logoWidth, logoHeight);

        const tcBorderWidth = 1; // Border width
        const tcPadding = 20; // Padding within the border
        const tcContentWidth = doc.internal.pageSize.getWidth() - 2 * tcPadding;


        // Set font and size for terms and conditions


        doc.setFontSize(15);
        doc.setFont('helvetica', 'bold');
        doc.text("Terms and Conditions for Payment of Service Fee", (doc.internal.pageSize.getWidth()/2) - 70, 50)
        

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12);
        

        // Define terms and conditions content
        const termsAndConditions = `
                1. Service Fee: By registering for our services, you agree to pay the specified service fee as outlined during the registration process. This fee is non-refundable and constitutes a registration fee for accessing and utilizing our services.

                2. Payment Terms: Payment of the service fee is due upon registration and must be made in full before accessing any services offered by Global Consultancy Services. We accept payment through bank transfer and cash.

                3. Non-Refundable Tariffs: Please note that the tariffs or fees associated with our services are non-refundable. Once payment is made, it is considered final and cannot be refunded under any circumstances, including but not limited to cancellation of services, changes in plans, or dissatisfaction with the services provided.

                4. Service Activation: Upon successful payment of the service fee, your registration will be activated, and you will gain access to the services as per the terms and conditions outlined in our service agreement.

                5. Cancellation and Termination: Global Consultancy Services reserves the right to cancel or terminate your registration and access to services if payment is not received as per the agreed terms or if there is a violation of our terms and conditions.

                6. Changes to Terms: Global Consultancy Services reserves the right to modify or update these terms and conditions regarding payment of service fees. Any changes will be communicated to you in advance, and your continued use of our services will indicate your acceptance of the modified terms.

                7. Contact Information: If you have any questions or concerns regarding the payment of service fees or these terms and conditions, please contact us at info@gcs-eu.com 

                By proceeding with the registration and payment of the service fee, you acknowledge that you have read, understood, and agreed to abide by these terms and conditions.`;

        const termsAndConditionsLines = doc.splitTextToSize(termsAndConditions, tcContentWidth - 10); // Adjust the width as needed
        doc.text(termsAndConditionsLines, tcPadding + 5, logoY + logoHeight + 30); // Adjust the Y position to be below the header

        try {
            const pdfBlob = doc.output('blob');
            doc.save(date + "-" + invoiceNo + '.pdf');

            const formData = new FormData();
            formData.append('filePDFF', pdfBlob, newDocFormData.email + "_" + date + "-" + invoiceNo + '.pdf');

            const url = `${process.env.REACT_APP_BACKEND_URL}/invoice/addInvoicePDF`;

            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            console.log('PDF file sent successfully to the backend.');

            const responseData = await response.json();
            console.log('Backend response:', responseData);
        } catch (error) {
            console.error('Error occurred while sending PDF file:', error);
        }
    };


    const [generatedId, setGeneratedId] = useState('');
    const [passportError, setPassportError] = useState(true);

    useEffect(() => {
        if (newDocModelStep && !newDocFormData.id) {
            generateNewId();
        }
        if (isEdit) {
            setPassportError(false);
        }
    }, [newDocModelStep]);

    const generateNewId = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/document/getlast`);
            if (response.data) {
                console.log("Document applications response:", response.data);
                let incrementedId = parseInt(response.data.id, 10) + 1;
                let paddedId = String(incrementedId).padStart(response.data.id.length, '0');
                console.log("New visa application id =", paddedId);
                setGeneratedId(paddedId);
                setNewDocFormData(prevFormData => ({
                    ...prevFormData,
                    id: paddedId
                }));
            } else {
                console.log("No existing Document applications found. Initializing ID to 0001");
                const newId = '0001';
                setGeneratedId(newId);
                setNewDocFormData(prevFormData => ({
                    ...prevFormData,
                    id: newId
                }));
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value); // Log to verify field updates
        setNewDocFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleClose = () => {
        setNewDocFormData({
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            passport: '',
            dateOfBirth: '11-11-1111',
            birthPlace: '',
            gender: '',
            nationality: '',
            issuance: '11-11-1111',
            expiry: '11-11-1111',
            id: '',
            status: 'Active',
            serviceFee: 0,
            progress: 0,
            image: '',
        });
        setNewDocModelStep(false);
    };

    const handleAddDoc = async () => {
        if (newDocFormData.firstName && newDocFormData.lastName && newDocFormData.passport && newDocFormData.phone && newDocFormData.id) {
            let formData={...newDocFormData, paymentMethod}


            if (isEdit) {
                // Update existing visa application
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/document/editDocApp/${newDocFormData._id}`, formData)
                    .then(response => {
                        console.log(response.data);
                        handleClose();
                        setUpdate(!update);
                    })
                    .catch(error => {
                        console.error('Error updating Document Application:', error);
                        toast.error('Error updating Document Application');
                    });
            } else {
                // Add new visa application
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/document/adddoc`, formData)
                    .then(response => {
                        console.log(response.data);
                        generateInvoicePdf();
                        handleClose();
                        setUpdate(!update);
                    })
                    .catch(error => {
                        console.error('Error adding Document Application:', error);
                        toast.error('Error adding Document Application');
                    });
            }
        } else {
            toast.error('Fill all the fields!');
            console.log(newDocFormData); // Log the data to see what is missing
        }
    };

    const handleImageChange = (e) => {
        setNewDocFormData((prevData) => ({ ...prevData, image: e.target.files[0] }));
    };

    const validatePassportPeriod = () => {
        const issuanceDate = new Date(newDocFormData.issuance);
        const expiryDate = new Date(newDocFormData.expiry);

        const timeDifference = Math.abs(expiryDate - issuanceDate);
        const yearDifference = timeDifference / (1000 * 60 * 60 * 24 * 365.25);

        if (yearDifference < 2) {
            setPassportError(true);
            console.log("there is an error in the date difference");
        } else {
            console.log("there is an error in the date difference NO ERROR");
            setPassportError(false);
        }
    };

    const formatDate = (isoString) => {
        if (!isoString){
            console.log(isoString)
            return ""
        }
        const date = new Date(isoString);
        if (isNaN(date.getTime())) return ''; // Check for invalid date
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    return (
        <>
            {newDocModelStep && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-20 p-5 px-10 border-b border-solid rounded-t border-blueGray-200 bg-[#FFF500]">
                                <h3 className="text-xl font-bold text-black">Document Application</h3>
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => handleClose()} />
                            </div>
                            <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 max-h-[70vh] overflow-auto'>
                                <div className='flex flex-row gap-20'>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>First Name</label>
                                            <input type='text' name='firstName' value={newDocFormData?.firstName} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Middle Name</label>
                                            <input type='text' name='middleName' value={newDocFormData?.middleName} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end  w-[300px] text-right'>
                                            <label>Last Name</label>
                                            <input type='text' name='lastName' value={newDocFormData?.lastName} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Email</label>
                                            <input type='email' name='email' value={newDocFormData?.email} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                    </div>
                                    {/* <div className='relative min-h-full w-[300px] sm:w-[200px] md:w-[250px] lg:w-[300px]'>
                                        {
                                            newDocFormData.image ? <img src={URL.createObjectURL(newDocFormData.image)}
                                                className='w-48 h-48 rounded-full' /> :
                                                <div className='w-[130px] h-[130px] md:w-[150px] md:h-[150px] lg:w-[200px] lg:h-[200px] bg-gray-300 rounded-full'></div>
                                        }

                                        <input onChange={handleImageChange} type={'file'} className='absolute bottom-0 hidden w-full cursor-pointer h-14' id='image' />
                                        <img onClick={() => {
                                            document.getElementById('image').click();
                                        }} src={Upload} className='absolute bottom-0 w-20 cursor-pointer right-10 h-14' />
                                    </div> */}
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row gap-20'>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Phone</label>
                                            <input type='text' name='phone' value={newDocFormData?.phone} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>ID</label>
                                            <input type='text' readOnly name='id' value={newDocFormData?.id} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                        </div>
                                    </div>
                                    <div className='flex flex-row gap-20'>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Date of Birth</label>
                                            <input type='date' name='dateOfBirth' value={formatDate(newDocFormData?.dateOfBirth)} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder="dd-mm-yyyy" min="1997-01-01" max="2030-12-31" />
                                        </div>
                                    </div>

                                    <div className='flex flex-row items-start gap-20'>
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Place of Birth</label>
                                                <input type='text' name='birthPlace' value={newDocFormData?.birthPlace} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label htmlFor='gender'>Gender</label>
                                                <select id='gender' name='gender' value={newDocFormData?.gender} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'>
                                                    <option>Select Gender</option>
                                                    <option value='male'>Male</option>
                                                    <option value='female'>Female</option>
                                                </select>
                                            </div>

                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Nationality</label>
                                                <select type='text' name='nationality' value={newDocFormData?.nationality} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'>
                                                    <option>Select Nationality</option>
                                                    {Nationalities.map((item, index) => (
                                                        <option key={index} value={item.nationality}>
                                                            {item.nationality}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Passport Number/ID Number</label>
                                                <input type='text' name='passport' value={newDocFormData?.passport} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            
                                            <div className={`flex-col justify-end w-[300px] text-right ${isEdit ? 'flex' : 'hidden'}`}>
                                                <label>Date of Expiry</label>
                                                <input readOnly name='expiry' value={formatDate(new Date(newDocFormData?.expiry), 'dd-MM-yyyy')} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Date of Issue</label>
                                                <input type='date' name='issuance' value={formatDate(newDocFormData?.issuance)} onChange={(e) => { handleInputChange(e);validatePassportPeriod() }} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder="dd-mm-yyyy" min="1997-01-01" max="2030-12-31" />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Date of Expiry</label>
                                                <input type='date' name='expiry' value={formatDate(newDocFormData?.expiry)} onChange={(e) => { handleInputChange(e); validatePassportPeriod() }} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder="dd-mm-yyyy" min="1997-01-01" max="2030-12-31" />
                                            </div>
                                            <div className={` w-[300px] ${passportError ? 'block' : 'hidden'} text-red-500`}>
                                                <label>Passport expiry should not be less than two years</label>
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Service Fee</label>
                                                <input type='number' name='serviceFee' value={newDocFormData?.serviceFee} onChange={(e) => handleInputChange(e)} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-start w-full gap-2 text-right'>
                                                <label>Select Payment Method</label>
                                                <div className='flex flex-row w-full gap-2'>
                                                    <input type='radio' name='payment' value='Cash' checked={paymentMethod==="Cash"} onClick={(e) => { setPaymentMethod(e.target.value); }} placeholder='' />
                                                    <label>Cash</label>
                                                </div>
                                                <div className='flex flex-row w-full gap-2'>
                                                    <input type='radio' name='payment' value='Card' checked={paymentMethod==="Card"} onClick={(e) => { setPaymentMethod(e.target.value); }} placeholder='' />
                                                    <label>Card</label>
                                                </div>
                                                <div className='flex flex-row w-full gap-2'>
                                                    <input type='radio' name='payment' value='Bank Transfer' checked={paymentMethod==="Bank Transfer"} onClick={(e) => { setPaymentMethod(e.target.value); }} placeholder='' />
                                                    <label>Bank Transfer</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-4 border-t border-solid rounded-b">
                                <button
                                    className={`px-20 py-4 mb-1 mr-1 text-black text-md font-bold bg-[#FFF500] rounded uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none ${isFormValid  ? 'opacity-100' : 'opacity-50'}`}
                                    type="button"
                                    onClick={handleAddDoc}
                                    disabled={!isFormValid}
                                >
                                    {isEdit ? 'Update Document Application' : 'Add Document Application'}
                                </button>
                                <button
                                    className="px-20 py-4 mb-1 mr-1 text-sm font-bold bg-[#F8FAFB] rounded uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                    type="button"
                                    onClick={() => { handleClose(); }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
