import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import RecruiterContext from '../../Components/RecruterContext';
import { format } from 'date-fns';
import NavBar from '../../Components/NavBar';
import Modal from './addDocument';
import * as XLSX from 'xlsx';

import Add from '../../Resources/Icons/add.png';
import Download1 from '../../Resources/Icons/download-1.png';
import Arrow from '../../Resources/Icons/downArrow.png';
import Edit from '../../Resources/Icons/edit.png';
import Search from '../../Resources/Icons/search.png';
import Profile from '../../Resources/Icons/profile.png';

const DocumentExpert = () => {
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState();
    const [update, setUpdate] = useState(false);

    const { sideBarStatus } = useContext(RecruiterContext);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        const userRole = sessionStorage.getItem('role');
        setUserRole(userRole);
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    const [docApplications, setDocApplications] = useState([]);
    const [allDocApplications, setAllDocApplications] = useState([]);

    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState();

    const getLastDocumentApp = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/document/getlast`);
            if (response.data) {
                let incrementedId = parseInt(response.data.id, 10) + 1;
                let paddedId = String(incrementedId).padStart(response.data.id.length, '0');
                setNewDocFormData(prevFormData => ({
                    ...prevFormData,
                    id: paddedId
                }));
            } else {
                setNewDocFormData(prevFormData => ({
                    ...prevFormData,
                    id: '0001'
                }));
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (!newDocFormData.id) {
            getLastDocumentApp();
        }
    }, []);

    const [newDocFormData, setNewDocFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        passport: '',
        dateOfBirth: '11-11-1111',
        birthPlace: '',
        gender: '',
        nationality: '',
        issuance: '11-11-1111',
        expiry: '11-11-1111',
        id: '',
        status: 'Active',
        serviceFee: 0,
        progress: 0,
        image: '',
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/document/getAllDocApp`);
            setDocApplications(response.data);
            setAllDocApplications(response.data);
        } catch (error) {
            console.error('Error fetching the document applications: ', error.message);
        }
    };

    const [selectedDocApp, setSelectedDocApp] = useState(null);
    useEffect(() => {
        fetchData();
        setSelectedDocApp(null);
    }, [update]);

    const appointment = useParams();

    const getAppointmentData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/appointment/getappointment/${appointment.id}`)
            console.log("Appointment's Data: ", response.data);
            const appointmentData = response.data;
            setNewDocFormData({
                firstName: appointmentData.name,
                lastName: appointmentData.surname,
                email: appointmentData.email,
                phone: appointmentData.phone,
                passport: '',
                dateOfBirth: '11-11-1111',
                birthPlace: '',
                gender: '',
                nationality: '',
                issuance: '11-11-1111',
                expiry: '11-11-1111',
                id: '',
                coverLetter: '',
                status: 'Active',
                serviceFee: 0,
                progress: 0,
                image: '',
                position: '',
            });    
            setAddModal(true);
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if(appointment.id) {
            console.log("Appointment's id: ", appointment.id);
            getAppointmentData();
        }
    },[])


    const [searchQuery, setSearchQuery] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());

    const filteredDocApplications = docApplications.filter((docApp) => {
        const docAppFNameMatch = docApp.firstName.toLowerCase().includes(searchQuery.toLowerCase());
        const docAppLNameMatch = docApp.lastName.toLowerCase().includes(searchQuery.toLowerCase());
        return  docAppFNameMatch || docAppLNameMatch;
    });

    useEffect(() => {
        const filteredDocApplications = allDocApplications.filter((docApp) => {
            const docAppDate = format(new Date(docApp.dateCreated), 'yyyy-MM-dd');
            const isSameDay = docAppDate === selectedDate;
            return isSameDay;
        });

        setDocApplications(filteredDocApplications);
    }, [selectedDate]);

    const [currentPage, setCurrentPage] = useState(1);
    const docApplicationsPerPage = 6;
    const totalPages = Math.ceil(filteredDocApplications.length / docApplicationsPerPage);

    const startIndex = (currentPage - 1) * docApplicationsPerPage;
    const endIndex = startIndex + docApplicationsPerPage;
    const docApplicationsToDisplay = filteredDocApplications.slice(startIndex, endIndex);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        setCurrentPage(1);
    }, []);

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtonsToShow = 5;

        if (totalPages <= maxPageButtonsToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1);
                pageNumbers.push("...");
            }
            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                if (i >= 1 && i <= totalPages) {
                    pageNumbers.push(i);
                }
            }
            if (rightEllipsis) {
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        }
        return pageNumbers;
    };

    function exportToExcel(users) {
        const headers = ['id', 'firstName', 'lastName', 'email', 'mobile', 'status'];
        const data = users.map(user => ({
            id: user.id || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            mobile: user.mobile || '',
            status: user.status || '',
           paymentMethod: user.paymentMethod || '', // Handle if paymentMethod is missing
            serviceFee: user.serviceFee || '', //
        }));
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Visa Applications Data');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Visa Applications Data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const [selectedDocAppDetails, setSelectedDocAppDetails] = useState(null);
    const [docApp, setDocApp] = useState(null);


    const handleDocAppClick = () => {
        console.log(docApp);
        setNewDocFormData(docApp);
        setEditModal(true);
    };

    useEffect(() => {
        if (docApp) {
            handleDocAppClick();
        }
    }, [docApp]);

    return (
        <div className='w-screen h-screen bg-gray-50'>
            <div className={`h-full absolute top-0 right-0 flex flex-col items-center pt-10 transition-all duration-500 ${sideBarStatus ? 'w-screen sm:w-[95%] md:w-[95%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%]' : 'w-screen sm:w-[95%] md:w-[95%] xl:w-[95%]'}`}>
                <div className='flex w-screen sm:w-[90%] lg:w-[80%] flex-row items-center justify-between gap-10'>
                    <h className='text-3xl font-bold'>Document Application</h>
                    <div className='flex flex-row items-center bg-gray-100 border-none border-gray-300 w-[50%] rounded-lg p-2'>
                        <input
                            type='text'
                            className='w-full h-full px-2 bg-transparent border-none rounded-lg outline-none'
                            placeholder='Search...'
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <img src={Search} className='w-6 h-6' alt='Search' />
                    </div>
                    <NavBar />
                </div>
                <div className=' w-screen sm:w-[90%] lg:w-[80%] mt-32 flex flex-col'>
                    <div className='flex flex-row justify-between w-full text-[#778CA2] font-semibold text-base items-center'>
                        <div className='flex flex-row gap-10'>
                            <div onClick={() => setAddModal(true)} className='flex flex-row gap-5 transition-all duration-200 cursor-pointer hover:scale-110'>
                                <span>Apply For a Document</span>
                                <img src={Add} className='w-5 h-5' alt='Add' />
                            </div>

                            <Modal
                                newDocModelStep={addModal}
                                setNewDocModelStep={setAddModal}
                                newDocFormData={newDocFormData}
                                setNewDocFormData={setNewDocFormData}
                                setPaymentMethod={setPaymentMethod}
                                paymentMethod={paymentMethod}
                                isEdit={!!newDocFormData._id}
                                update={update}
                                setUpdate={setUpdate}
                                methodOfPayment={paymentMethod}
                            />

                            <div onClick={() => exportToExcel(allDocApplications)} className='flex flex-row gap-5 transition-all duration-200 cursor-pointer hover:scale-110'>
                                <span>Download</span>
                                <img src={Download1} className='w-5 h-5' alt='Download' />
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-10'>
                            <input
                                type='date'
                                className="p-2 px-4 font-bold text-white bg-[#CA9731] bg-opacity-70 rounded hover:bg-[#CA9731]"
                                onChange={(e) => {
                                    setSelectedDate(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-3 mt-10'>
                        <div className={`grid w-full gap-5 font-semibold  text-center grid-cols-6`}>
                            <span className={`col-span-2`}>Actions</span>
                            <span>Last Edited</span>
                            <span>Date Created</span>
                            <span>ID</span>
                            <span>User Name</span>
                        </div>
                        {selectedDocAppDetails == null
                            ? <div></div>
                            : <div></div>}
                        <div className='flex flex-col w-full h-full gap-5 '>
                            {docApplicationsToDisplay.map((docApp) => (
                                <div className={`grid items-center rounded-lg shadow-md gap-5 py-5 text-center grid-cols-6 bg-[#FFF500]`} >
                                    <div className={`flex flex-row items-center justify-center gap-5 col-span-2`}>
                                        <img onClick={() => { setDocApp(docApp); setNewDocFormData(docApp); setPaymentMethod(docApp.paymentMethod) }} src={Edit} className='w-5 h-5 transition-all duration-200 cursor-pointer hover:scale-125' alt='Edit' />
                                    </div>
                                    <span>{format(new Date(docApp.dateEdited), 'dd-MM-yyyy')}</span>
                                    <span>{format(new Date(docApp.dateCreated), 'dd-MM-yyyy')}</span>
                                    <span>{docApp.id}</span>
                                    <div className='flex flex-row items-center justify-end gap-3 mr-5'>
                                        <span>{docApp.firstName} {docApp.lastName}</span>
                                        <img src={docApp.profileImage ? docApp.profileImage : Profile} className='w-10 h-10 rounded-full' alt='Profile' />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-start mt-4">
                        <div
                            onClick={currentPage === 1 ? null : () => setCurrentPage(currentPage - 1)}
                            className={`flex flex-row gap-1 mt-1 ml-2 cursor-pointer ${currentPage === 1 ? 'opacity-50' : ''}`}
                        >
                            <img src={Arrow} className='w-3 h-2 mt-[11px] rotate-90' alt='Previous' />
                            <span>prev</span>
                        </div>
                        {getPageNumbers().map((pageNumber, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(pageNumber)}
                                className={`mx-2 ${currentPage === pageNumber ? "bg-[#CA9731] text-white rounded py-1 px-3" : " text-black rounded py-1 px-3"
                                    } rounded`}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <div
                            onClick={currentPage === totalPages ? null : () => setCurrentPage(currentPage + 1)}
                            className={`flex flex-row gap-1 mt-1 ml-2 cursor-pointer ${currentPage === totalPages ? 'opacity-50' : ''}`}
                        >
                            <span>next</span>
                            <img src={Arrow} className='w-3 h-2 mt-[10px] -rotate-90' alt='Next' />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                newDocModelStep={editModal}
                setNewDocModelStep={setEditModal}
                newDocFormData={newDocFormData}
                setNewDocFormData={setNewDocFormData}
                setPaymentMethod={setPaymentMethod}
                paymentMethod={paymentMethod}
                isEdit={!!newDocFormData._id}
                update={update}
                setUpdate={setUpdate}
                methodOfPayment={paymentMethod}
            />
        </div>
    );
};

export default DocumentExpert;
