import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';

import Cross from '../../Resources/Icons/x.png'

const RequestModal = ({ requestModal, setRequestModal , notification }) => {

    const [job, setJob] = useState({
        company: '',
        category: '',
        position: '',
        location: '',
        period: '',
        qualification: '',
        amenities: '',
        permit: '',
        overtime: '',
    });
    const [recruiter, setRecruiter] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        status: 'Inactive',
        coverLetter: '',
        dateEdited: Date.now()
    });
    const [agent, setAgent] = useState({
        firstName: '',
        lastName: '',
        email: '',
        id: '',
        mobile: '',
        image: '',
        agentType: '',
    });

    const fetchAgent = async () => {
        try {
            const email = sessionStorage.getItem('user');
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/agent/getagent/${notification.agent}`);
            setAgent(response.data);
        } catch (error) {
            console.error('Error fetching the Agents: ', error.message);
        }
    }
    

    const fetchRecruiter =  async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recruiter/getrecruiter/${notification.recruiter}`);
            setRecruiter(response.data);
        } catch (error) {
            console.error('Error fetching the Agents: ', error.message);
        }
    }

    const fetchJob =  async() => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/job/getjob/${notification.job}`);
            setJob(response.data);
        } catch (error) {
            console.error('Error fetching the Agents: ', error.message);
        }
    }

    useEffect(() => {
        fetchRecruiter();
        fetchAgent();
        fetchJob();
    }, [requestModal]);

    return (
        <>
            {requestModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-20 p-5 px-10 border-b border-solid rounded-t border-blueGray-200">
                                <h3 className="font-bold text-black p-2 px-5 rounded-xl bg-[#FDCF8B]">Request</h3>
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => setRequestModal(false)} />
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 md:gap-28 px-10 font-normal text-black w-fit py-5 pb-10 bg-[#F8FAFB]'>
                                <div className='flex flex-col gap-5'>
                                    <h className='text-lg'>Agent Details</h>
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>First Name</label>
                                            <input readOnly type='text' name='firstName' value={agent?.firstName} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />                                            
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Last Name</label>
                                            <input readOnly type='text' name='lastName' value={agent?.lastName} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />                                            
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Email ID</label>
                                            <input readOnly type='text' name='email' value={agent?.email} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Mobile No</label>
                                            <input readOnly type='text' name='mobile' value={agent?.mobile} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <h className='text-lg'>Employee Details</h>
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Name</label>
                                            <input readOnly type='text' name='name' value={recruiter?.firstName} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Email ID</label>
                                            <input readOnly type='text' name='email' value={recruiter?.email} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label readOnly>Mobile No</label>
                                            <input type='text' name='mobile' value={recruiter?.phone} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <h className='text-lg'>Company Details</h>
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Company</label>
                                            <input readOnly type='text' name='company' value={job?.company} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Category</label>
                                            <input readOnly type='text' name='category' value={job?.category} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Location</label>
                                            <input readOnly type='text' name='location' value={job?.location} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-end justify-end p-4 px-10 border-t border-solid rounded-b border-blueGray-200">
                                <button
                                    className="px-28 py-4 mb-1 mr-1 text-sm font-bold text-gray-400 bg-[#F8FAFB] hover:bg-slate-300 rounded-full uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                    type="button"
                                    onClick={() => setRequestModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default RequestModal;
