import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cross from '../../Resources/Icons/x.png';
import Upload from '../../Resources/Icons/Upload.png';
import Nationalities from '../../Resources/Nationalities.json';
import { toast } from 'react-toastify';
import SearchableDropdown from '../../Components/SearchableDropdown';
import { format } from 'date-fns';

const EditModal = ({ newRecruiterModelStep, setNewRecruiterModelStep, newRecruiterFormData, setNewRecruiterFormData, update, setUpdate, setNextStep, questionaire, setIsEdit }) => {
    const [isPassportValid, setIsPassportValid] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setNewRecruiterFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        setNewRecruiterFormData((prevData) => ({...prevData, image:e.target.files[0]}));
    }

    const handleClose = () => {
        setNewRecruiterModelStep(false);
        setIsEdit(false)
    };

    const [passportError, setPassportError] = useState(false);
    const validatePassportPeriod = () => {
        // Convert the date strings to Date objects
        const issuanceDate = new Date(newRecruiterFormData.issuance);
        const expiryDate = new Date(newRecruiterFormData.expiry);

        // Calculate the difference in time (milliseconds)
        const timeDifference = Math.abs(expiryDate - issuanceDate);
        console.log(newRecruiterFormData.issuance);
        console.log(newRecruiterFormData.expiry);

        // Convert time difference to years
        const yearDifference = timeDifference / (1000 * 60 * 60 * 24 * 365.25);

        // Check if the difference is less than 2 years
        if (yearDifference < 2) {
            setPassportError(true);
            console.log("there is an error in the date difference");
        } else {
            console.log("there is an error in the date difference NO ERROR");
            setPassportError(false);
        }
    }

    const [value, setValue] = useState("Select option...");
      
    const printDetails = () => {
        console.log(newRecruiterFormData);
    }

    const handleEdit = async () => {
        try {
            const response = axios.post(`${process.env.REACT_APP_BACKEND_URL}/recruiter/editrecruiter/${newRecruiterFormData._id}`, newRecruiterFormData)
            console.log(response.data);
            setUpdate(!update)
        } catch (error) {
            console.error('Error updating Document Application:', error);
            toast.error('Error updating Document Application');   
        }
    }

    return (
        <>
            {newRecruiterModelStep && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-20 p-5 px-10 border-b border-solid rounded-t border-blueGray-200 bg-[#FFF500]">
                                <h3 className="text-xl font-bold text-black">Edit Candidate</h3>
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => handleClose()} />
                            </div>
                            <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 max-h-[70vh] overflow-auto'>
                                <div className='flex flex-row gap-20'>
                                    <div className='flex flex-col gap-2'>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>First Name</label>
                                            <input type='text' name='firstName' value={newRecruiterFormData?.firstName} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'  />
                                        </div>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Middle Name</label>
                                            <input type='text' name='middleName' value={newRecruiterFormData?.middleName} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'  />
                                        </div>
                                        <div className='flex flex-col justify-end  w-[300px] text-right'>
                                            <label>Last Name</label>
                                            <input type='text' name='lastName' value={newRecruiterFormData?.lastName} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'  />
                                        </div>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Email</label>
                                            <input type='text' name='email' value={newRecruiterFormData?.email} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'  />
                                        </div>
                                    </div>
                                    <div className='relative min-h-full w-[300px] sm:w-[200px] md:w-[250px] lg:w-[300px]'>
                                        {
                                            newRecruiterFormData.image ? <img src={URL.createObjectURL(newRecruiterFormData.image)}
                                                                  className='w-48 h-48 rounded-full'/> :
                                                <div className='w-[130px] h-[130px] md:w-[150px] md:h-[150px] lg:w-[200px] lg:h-[200px] bg-gray-300 rounded-full'></div>
                                        }

                                        <input onChange={handleImageChange} type={'file'} className='absolute bottom-0 hidden w-full cursor-pointer h-14' id='image' />
                                        <img onClick={()=>{
                                            document.getElementById('image').click();
                                        }} src={Upload} className='absolute bottom-0 w-20 cursor-pointer right-10 h-14' />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row gap-20'>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Phone</label>
                                            <input type='text' name='phone' value={newRecruiterFormData?.phone} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'  />
                                        </div>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>ID</label>
                                            <input type='text' readOnly name='id' value={newRecruiterFormData?.id} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                        </div>
                                    </div>
                                    <div className='flex flex-row gap-20'>
                                        <div className='flex flex-col justify-end w-[300px] text-right'>
                                            <label>Date of Birth</label>
                                            <input type='date' name='dateOfBirth' onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                        </div>
                                        <div className={`flex flex-col justify-end w-[300px] text-right`}>
                                            <label>Questionnaire</label>
                                            <button onClick={() => { setNextStep(true); setNewRecruiterModelStep(false); setIsEdit(true)}} type='text' name='' className={`w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200  rounded hover:bg-gray-300 hover:scale-105 transition-all duration-300 ${questionaire ? 'bg-green-500' : 'bg-red-300'}`}>Questionnaire</button>
                                        </div>
                                    </div>
                                   
                                    <div className='flex flex-row items-start gap-20'>
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Place of Birth</label>
                                                <input type='text' name='birthPlace' value={newRecruiterFormData?.birthPlace} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label htmlFor='gender'>Gender</label>
                                                <select id='gender' name='gender' value={newRecruiterFormData?.gender} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'>
                                                    <option>Select Gender</option>
                                                    <option value='male'>Male</option>
                                                    <option value='female'>Female</option>
                                                </select>
                                            </div>

                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Nationality</label>
                                                <select type='text' name='nationality' value={newRecruiterFormData?.nationality} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded'>
                                                    <option>Select Nationality</option>
                                                    {Nationalities.map((item, index) => (
                                                        <option key={index} value={item.nationality}>
                                                            {item.nationality}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Passport Number</label>
                                                <input type='text' name='passport' value={newRecruiterFormData?.passport} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Date of Issue</label>
                                                <input readOnly name='issuance' value={format(new Date(newRecruiterFormData?.issuance), 'dd-MM-yyyy')} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Date of Expiry</label>
                                                <input readOnly name='expiry' value={format(new Date(newRecruiterFormData?.expiry), 'dd-MM-yyyy')}  className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Date of Issue</label>
                                                <input type='date' name='issuance' onChange={(e) => {handleInputChange(e); validatePassportPeriod() }} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Date of Expiry</label>
                                                <input type='date' name='expiry' onChange={(e) => {handleInputChange(e); validatePassportPeriod() }} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className={` w-[300px] ${passportError ? 'block' : 'hidden'} text-red-500`}>
                                                <label>Passport expiry should not be less than two years</label>     
                                            </div>
                                        </div>
                                        
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex flex-row gap-20'>
                                                <div className='flex flex-col justify-end w-[300px] text-right'>
                                                    <label>Job Applying For</label>
                                                    <SearchableDropdown
                                                        formData = {newRecruiterFormData}
                                                        setFormData = {setNewRecruiterFormData}
                                                        label="name"
                                                        id="id"
                                                        selectedVal={value}
                                                        handleChange={(val) => setValue(val)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex flex-col justify-end w-[300px] text-right'>
                                                <label>Service Fee</label>
                                                <input type='number' readOnly name='fee' value={newRecruiterFormData?.serviceFee} onChange={handleInputChange} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-4 border-t border-solid rounded-b">
                                <button
                                    className={`px-20 py-4 mb-1 mr-1 text-black text-md font-bold bg-[#FFF500] rounded uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none ${passportError ? 'opacity-50' : 'opacity-100'}`}
                                    type="button"
                                    onClick={() => {
                                        if (newRecruiterFormData?.firstName && newRecruiterFormData?.lastName && newRecruiterFormData?.email
                                            && newRecruiterFormData?.passport && newRecruiterFormData?.dateOfBirth
                                            && newRecruiterFormData?.birthPlace && newRecruiterFormData?.gender && newRecruiterFormData?.nationality
                                            && newRecruiterFormData?.issuance && newRecruiterFormData?.expiry && newRecruiterFormData?.position) {
                                            handleEdit();
                                            setNewRecruiterModelStep(false);
                                        } else {
                                            toast.error('Fill all the fields!');
                                            printDetails();
                                        }
                                    }}
                                    disabled={passportError}
                                >
                                    Update Recruiter
                                </button>
                                <button
                                    className="px-20 py-4 mb-1 mr-1 text-sm font-bold bg-[#F8FAFB] rounded uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                    type="button"
                                    onClick={() => { handleClose(); }}
                                >
                                    Close
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditModal;
