import { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./styles.css"
import { toast } from "react-toastify";

const SearchableDropdown = ({label, id, formData, setFormData}) => {
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const inputRef = useRef(null);

    const [newPostion, setNewPosition] = useState('');
    const [update, setUpdate] = useState(false);

    // const handleAddNew = async () => {
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/job/addpostion`, {newPostion})
    //         console.log(response.data);
    //         setUpdate(!update)
    //     } catch (error) {
    //         console.log(error.message);
    //     }
    // } 

    const [options, setOptions] = useState([]);

    const getJobPositions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/job/getjobpositions`)
            console.log(response.data);
            setOptions(response.data);
        } catch (error) {
            console.log(error.message);
        }
    } 

    useEffect(() => {
        getJobPositions();
    }, [update])

    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    const selectOption = (option) => {
        setQuery(() => "");
        setIsOpen((isOpen) => !isOpen);
    };

    function toggle(e) {
        setIsOpen(e && e.target === inputRef.current);
    }

    const filter = (options) => {
        return options.filter(
        (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        );
    };

    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={formData.position}
            name="position"
            onChange={(e) => {
              setQuery(e.target.value);
              setNewPosition(e.target.value);
              handleInputChange("position", e.target.value);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {/* <div className="flex items-center justify-center w-full p-2 text-center">
            <button onClick={() => {
                        if(newPostion == '') {
                            toast.error("Write a job position first!")
                        } else {
                            handleAddNew();
                        }
                    }
                } className="border-[1px] border-gray-500 p-1 rounded hover:bg-gray-300 text-xs w-full">Add to the List</button>
        </div> */}
        { filter(options).map((option, index) => {
          return (
            <div
                name="position"
                value={option}
                onClick={(e) => {selectOption(option); handleInputChange("position", option.name)}}
                className={`option `}
                key={`${id}-${index}`}
            >
              {option[label]}
            </div>
          );
        })
    }
      </div>
    </div>
  );
};

export default SearchableDropdown;
