import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cross from '../../Resources/Icons/x.png';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const ReschedulePopup = ({ showPopup, setShowPopup, appointment, setUpdate, update }) => {
    const [newTime, setNewTime] = useState(appointment?.time || '');
    const [newDate, setNewDate] = useState(appointment?.date || '');

    const handleSaveChanges = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/appointment/reschedule`, { appointment, newTime, newDate });
            setUpdate(!update);
            toast.success('Appointment rescheduled successfully');
            appointment.time = newTime;
            appointment.date = newDate;
            setShowPopup(false);
        } catch (error) {
            console.error('Error rescheduling appointment:', error.message);
            toast.error('Failed to reschedule appointment');
        }
    };

    return (
        <>
            {showPopup && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-5 p-5 px-5 border-b border-solid rounded-t border-blueGray-200">
                                <h3>Reschedule Appointment</h3>
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => setShowPopup(false)} />
                            </div>
                            <div className='flex flex-col gap-3 px-20 py-10 bg-[#F8FAFB]'>
                                <div className='flex flex-col gap-1'>
                                    <label>New Time</label>
                                    <input type='time' name='newTime' value={newTime} onChange={(e) => setNewTime(e.target.value)} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                </div>
                                <div className='flex flex-col gap-1'>
                                    <label>New Date</label>
                                    <input type='date' name='newDate' value={newDate} onChange={(e) => setNewDate(e.target.value)} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                </div>
                                <button
                                    className="px-20 py-4 mt-4 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded"
                                    type="button"
                                    onClick={handleSaveChanges}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const Modal = ({ showModal, setShowModal, appointment, setUpdate, update, type }) => {
    const [status, setStatus] = useState('');
    const [showReschedulePopup, setShowReschedulePopup] = useState(false);
    const [isPastDue, setIsPastDue] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (appointment) {
            const checkIfPastDue = () => {
                const now = new Date();
                const appointmentTime = new Date(`${appointment.date}T${appointment.time}`);
                setIsPastDue(now > appointmentTime);
            };

            checkIfPastDue();
            const interval = setInterval(checkIfPastDue, 60000); // Check every minute

            return () => clearInterval(interval);
        }
    }, [appointment]);

    const handleNotShowed = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/appointment/updateStatus`, { appointment, status });
            setUpdate(!update);
        } catch (error) {
            console.error('Error updating appointment status:', error.message);
        }
    };

    const handleProceedToRegistration = () => {
        if (type === 'organization') {
            navigate(`/jobs/${appointment._id}`)
        } else if (type === 'person'){
            if (appointment.title == 'New Employee Registration') {
                navigate(`/recruters/${appointment._id}`)
            } else if (appointment.title == 'Document Expertise') {
                navigate(`/document/${appointment._id}`)
            } else if (appointment.title == 'Immigration Consultant') {
                navigate(`/emmigration/${appointment._id}`)
            }
        }
        // Add your registration logic here
    };

    useEffect(() => {
        if (status !== '') {
            handleNotShowed();
            setShowModal(false);
            setStatus('');
        }
    }, [status]);

    useEffect(() => {
        if (!showModal) {
            setShowReschedulePopup(false);
        }
    }, [showModal]);

    if (!appointment) {
        return null; // or a loading indicator, or any other placeholder
    }

    return (
        <>
            {showModal && (
                <div className="fixed z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none inset-y-5 inset-2 sm:inset-y-0 sm:inset-0 focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-end gap-5 p-5 px-5 border-b border-solid rounded-t border-blueGray-200">
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => setShowModal(false)} />
                            </div>
                            <div className={`flex flex-row gap-10 px-20 font-normal text-black w-fit py-10 pb-20 ${isPastDue ? 'past-due-row' : ''}`}>
                                <div className='flex flex-col gap-3 w-[50%]'>
                                    <div className='flex flex-col gap-1'>
                                        <label>Title of Appointment</label>
                                        <input type='text' readOnly name='title' value={appointment.title} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                    </div>
                                    <div className='flex flex-row gap-3'>
                                        <div className='flex flex-col gap-1'>
                                            <label>Time</label>
                                            <input type='time' readOnly name='time' value={appointment.time} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <label>Date</label>
                                            <span className='w-full min-w-[125px] p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder=''>{format(new Date(appointment.date), 'MM-dd-yyyy')}</span>
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <label>Phone</label>
                                        <input type='text' readOnly name='phone' value={appointment.phone} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <label>Email</label>
                                        <input type='text' readOnly name='email' value={appointment.email} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <label>Description</label>
                                        <textarea type='text' readOnly value={appointment.description} name='description' className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3 w-[50%]'>
                                    {type === 'person' ? (
                                        <>
                                            <div className='flex flex-col gap-1'>
                                                <label>Name</label>
                                                <input type='text' readOnly name='name' value={appointment.name} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <label>Surname</label>
                                                <input type='text' readOnly name='surname' value={appointment.surname} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='flex flex-col gap-1'>
                                                <label>Company Name</label>
                                                <input type='text' readOnly name='companyName' value={appointment.companyName} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                            <div className='flex flex-col gap-1'>
                                                <label>Business Number</label>
                                                <input type='text' readOnly name='businessNumber' value={appointment.businessNumber} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='' />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-4 border-t border-solid rounded-b border-blueGray-200">
                                <button
                                    className={`px-20 py-4 mb-1 mr-1 text-m font-bold font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md  background-transparent focus:outline-none ${isPastDue ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500'}`}
                                    type="button"
                                    onClick={() => setShowReschedulePopup(true)}
                                    disabled={isPastDue}
                                >
                                    Reschedule Appointment
                                </button>
                                <button
                                    className="px-20 py-4 mb-1 mr-1 text-m font-bold font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded"
                                    type="button"
                                    onClick={handleProceedToRegistration}
                                >
                                    Proceed to Registration
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ReschedulePopup
                showPopup={showReschedulePopup}
                setShowPopup={setShowReschedulePopup}
                appointment={appointment}
                setUpdate={setUpdate}
                update={update}
            />
        </>
    );
};

export default Modal;
