import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const NavBar = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const email = sessionStorage.getItem('user');
    const role = sessionStorage.getItem('role');

    const getUser = async () => {
        try {
            if (role === 'admin') {
                const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/getuser`, {
                    params: { email }
                });
                setUser(res.data);
                console.log(res.data);
            } else {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/agent/getAgentByEmail`, {
                    params: { email }
                });
                setUser(response.data.agent);
                console.log(response.data.agent);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <div className='flex flex-row items-center gap-10'>
            <div
                className='relative flex items-center justify-center w-10 h-10 text-center align-middle bg-gray-200 rounded-lg cursor-pointer group'
            >
                {role === 'admin' ? (
                    <span className='text-2xl font-bold transition-transform duration-200 transform group-hover:scale-110'>
                        A
                    </span>
                ) : user?.profileImage ? (
                    <img 
                        src={user.profileImage} 
                        className='w-full h-full transition-transform duration-200 transform rounded-full group-hover:scale-110' 
                    />
                ) : (
                    <span className='text-xl font-bold transition-transform duration-200 transform group-hover:scale-110'>
                        {user?.firstName ? user.firstName.charAt(0).toUpperCase() : ''}
                    </span>
                )}
                <div
                    className='absolute left-0 flex flex-col items-center hidden gap-2 p-2 -bottom-[90px] group-hover:flex hover:flex bg-white rounded-lg shadow-lg w-[120px]'
                >
                    {role === 'admin' ? (
                        <span className='px-2 py-1 rounded-lg cursor-pointer '>
                            Admin
                        </span>
                    ) : (
                        <span className='px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-100'>
                            {user?.firstName} {user?.lastName}
                        </span>
                    )}
                    <span
                        className='px-2 py-1 text-red-600 rounded-lg cursor-pointer hover:bg-red-100'
                        onClick={() => {
                            sessionStorage.clear();
                            navigate('/');
                        }}
                    >
                        Log out
                    </span>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
