import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import RecruiterContext from '../../Components/RecruterContext';
import NavBar from '../../Components/NavBar';
import Modal from './addRecruter';
import RecruterQuestionaireModel from './addRecruterQuestionaire';
import RecruterConfirmModel from './addRecruterConfirm';
import DocumentUploadForm from './DocumentUploadForm';
import BackgroundCheckFrom from './BackgroundCheckFrom';
import ApplicationCompletionForm from './ApplicationCompletionForm';
import CV from './CV';
import * as XLSX from 'xlsx';
import TransferModal from '../../Components/transferForm';
import TransferDetails from './transferDetails';
import EditModal from './editRecruter';
import Add from '../../Resources/Icons/add.png';
import Download from '../../Resources/Icons/download.png';
import Download1 from '../../Resources/Icons/download-1.png';
import Filter from '../../Resources/Icons/filter.png';
import Arrow from '../../Resources/Icons/downArrow.png';
import Eye from '../../Resources/Icons/eye.png';
import Edit from '../../Resources/Icons/edit.png';
import Delete from '../../Resources/Icons/delete.png';
import Search from '../../Resources/Icons/search.png';
import Profile from '../../Resources/Icons/profile.png';
import ADD from '../../Resources/Icons/addc.png';
import Import from '../../Resources/Icons/import.png';
import DocsDownload from './downloadDocs';
import Document from '../../Resources/Icons/icons8-documents-96.png';
import Logo from '../../Resources/logo_bg_rm.jpg';
import jsPDF from 'jspdf';

const Recruters = () => {
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState();
    const [update, setUpdate] = useState(false);

    const { recruitersStatus } = useContext(RecruiterContext);
    const { sideBarStatus } = useContext(RecruiterContext);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        const userRole = sessionStorage.getItem('role');
        setUserRole(userRole);
        if (!token) {
            navigate('/login');
        }
    }, []);

    const [recruters, setRecruters] = useState([]);
    const [allRecruters, setAllRecruters] = useState([]);

    // All modals are defined here
    const [addModal, setAddModal] = useState(false);
    const [questionareModel, setQuestionareModal] = useState(false);
    const [addRecruiterConfirmModal, setAddRecruiterConfirmModal] = useState(false);
    const [documentModal, setDocumentModal] = useState(false);
    const [backgroundModal, setBackgroundModal] = useState(false);
    const [completionModal, setCompletionModal] = useState(false);
    const [cvModal, setCVModal] = useState(false);
    const [showDocDownload, setShowDocDownload] = useState(false);

    const [newRecruiterModelStep, setNewRecruiterModelStep] = useState(0);
    const [editModal, setEditModal] = useState(false);
    const [transferModal, setTransferModal] = useState(false);
    const [transferDetails, setTransferDetails] = useState(false);
    const [questionaire, setQuestionaire] = useState(false); // to make sure that questionaire is filled

    const [paymentMethod, setPaymentMethod] = useState('');


    const [passportError, setPassportError]=useState(false)
    const [newRecruiterFormData, setNewRecruiterFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        passport: '',
        dateOfBirth: Date.now(),
        birthPlace: '',
        gender: '',
        nationality: '',
        issuance: Date.now(),
        expiry: Date.now(),
        id: '',
        coverLetter: '',
        status: 'Active',
        serviceFee: 30,
        progress: 0,
        image: '',
        position: ''
    });


    const [contactDetails, setContactDetails] = useState({
        completeAddress: '',
        city: '',
        postalCode: '',
        province: '',
        country: '',
        email: '',
        phoneNumber: '',
        fixNumber: '',
    });

    const [careerGoals, setCareerGoals] = useState({
        targetJob: '',
        industry: '',
        workEnvironment: ''
    });

    const [education, setEducation] = useState({
        degree: '',
        field: '',
        university: '',
        place: '',
        experience: '',
        projects: '',
        information: '',
        responsibilities: '',
        toolAndSoftwares: '',
        approach: ''
    });

    const [workExperiences, setWorkExperiences] = useState([]);

    const [skills, setSkills] = useState([]);
    const [skillsInfo, setSkillsInfo] = useState({
        technicalSkills: '',
        softSkills: '',
        specialSkills: ''
    });

    const [certificates, setCertificates] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [memberships, setMemberships] = useState([]);
    const [volunteerExperinces, setVolunteerExperinces] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [references, setReferences] = useState([]);

    const [problemSolving, setProblemSolving] = useState({
        challengingSituation: '',
        deadlines: '',
        qualityWork: ''
    });

    const [teamWork, setTeamWork] = useState({
        teamProject: '',
        handleConflicts: '',
        communicationStyle: ''
    });

    const [customerService, setCustomerService] = useState({
        customerSatisfaction: '',
        experience: '',
        strategies: ''
    });

    const [adaptibility, setAdaptibility] = useState({
        adaptToChanges: '',
        learningSituation: '',
        careerGoals: ''
    });

    const [companyMotivation, setCompanyMotivation] = useState({
        interests: '',
        contributions: '',
        fitForPosition: ''
    });

    const [additionalQuestions, setAdditionalQuestions] = useState({
        softwareProficiency: '',
        awardsAndRecognition: '',
        problemSolving: '',
        adaptabilityAndInitiative: '',
        continuousLearning: '',
        coreStrengths: '',
        achievements: ''
    });
    const [recruiterFormData, setRecruiterFormData] = useState();

    const appointment = useParams();

    const getAppointmentData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/appointment/getappointment/${appointment.id}`)

            const appointmentData = response.data;
            setNewRecruiterFormData({
                firstName: appointmentData.name,
                lastName: appointmentData.surname,
                email: appointmentData.email,
                phone: appointmentData.phone,
                passport: '',
                dateOfBirth: Date.now(),
                birthPlace: '',
                gender: '',
                nationality: '',
                issuance: Date.now(),
                expiry: Date.now(),
                id: '',
                coverLetter: '',
                status: 'Active',
                serviceFee: 30,
                currency: '€',
                progress: 0,
                image: '',
                position: '',
            });
            setAddModal(true);
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if (appointment.id) {
            getAppointmentData();
        }
    }, [])


    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recruiter/getallrecruiters`);
            setRecruters(response.data);
            setAllRecruters(response.data);
        } catch (error) {
            console.error('Error fetching the Agents: ', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, [transferModal, update]);

    const [searchQuery, setSearchQuery] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredRecruters, setFilteredRecruters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recrutersPerPage = 5; // Number of rows per page

 
    useEffect(() => {
        const filtered = allRecruters.filter((recruiter) => {
            const agentFNameMatch = recruiter.firstName.toLowerCase().includes(searchQuery.toLowerCase());
            const agentLNameMatch = recruiter.lastName.toLowerCase().includes(searchQuery.toLowerCase());
            return agentFNameMatch || agentLNameMatch;
        });
        setFilteredRecruters(filtered);
    }, [searchQuery, allRecruters]);
    

    
   useEffect(() => {
        // Filter recruiters based on their status
        const filtered = recruters.filter((recruter) => {
            if (recruitersStatus === "Active" && recruter.status === "Active") {
                return true;
            }
            if (recruitersStatus === "Inactive" && recruter.status === "Inactive") {
                return true;
            }
            return false;
        });
        setFilteredRecruters(filtered);
    }, [recruters, recruitersStatus]);

    // Check if pagination is needed
    const needsPagination = filteredRecruters.length > recrutersPerPage;

    // Paginate the filtered recruiters only if necessary
    const startIndex = (currentPage - 1) * recrutersPerPage;
    const endIndex = needsPagination ? startIndex + recrutersPerPage : filteredRecruters.length;
    const recrutersToDisplay = filteredRecruters.slice(startIndex, endIndex);
    const totalPages = Math.ceil(filteredRecruters.length / recrutersPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

  

    useEffect(() => {
        setCurrentPage(1);
    }, [recruitersStatus]);

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPageButtonsToShow = 5;

        if (totalPages <= maxPageButtonsToShow) {
            // Show all pages
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Show a limited number of pages with ellipsis
            const leftEllipsis = currentPage > 2;
            const rightEllipsis = currentPage < totalPages - 1;

            if (leftEllipsis) {
                pageNumbers.push(1);
                pageNumbers.push("...");
            }
            for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                if (i >= 1 && i <= totalPages) {
                    pageNumbers.push(i);
                }
            }
            if (rightEllipsis) {
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        }
        return pageNumbers;
    };

    function exportToExcel(users) {
        const headers = ['id', 'firstName', 'lastName', 'email', 'mobile', 'status'];
        const data = users.map(user => ({
            id: user.id || '', // Handle if id is missing
            firstName: user.firstName || '', // Handle if firstName is missing
            lastName: user.lastName || '', // Handle if lastName is missing
            email: user.email || '', // Handle if email is missing
            mobile: user.mobile || '', // Handle if mobile is missing
            status: user.status || '', // Handle if status is missing
            paymentMethod: user.paymentMethod || '', // Handle if paymentMethod is missing
            serviceFee: user.serviceFee || '', //
        }));
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Recruiters Data');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Recruiters Data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const [selectedRecruiter, setSelectedRecruiter] = useState(null);
    const [selectedRecruiterDetails, setSelectedRecruiterDetails] = useState(null);
    const [recruter, setRecruter] = useState(null);

    const downloadCV = async (originalFilename, downloadFilename) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/download-cv/${originalFilename}_CV.pdf`);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = downloadFilename; // Use the specified download filename
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading CV:', error);
        }
    };

    const handleRecruterClick = () => {
        if (recruter.informationProgress === 'basic') {
            setRecruiterFormData(recruter);
            setDocumentModal(true);
        } else if (recruter.informationProgress === 'documents') {
            setRecruiterFormData(recruter);
            setBackgroundModal(true);
        } else if (recruter.informationProgress === 'background') {
            setRecruiterFormData(recruter);
            setCompletionModal(true);
        }
    };
    useEffect(() => {
        if (recruter) {
            handleRecruterClick();
        }
    }, [recruter]);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const [isEdit, setIsEdit] = useState(false);
    const [questionaireSavedData, setQuestionaireSavedData] = useState({})
    const [isUpdateRecruiter, setIsUpdateRecruiter] = useState(false)

    function refreshQuestionare(){
        setContactDetails({
            completeAddress: '',
            city: '',
            postalCode: '',
            province: '',
            country: '',
            email: '',
            phoneNumber: '',
            fixNumber: '',
        })

        setCareerGoals({
            targetJob: '',
            industry: '',
            workEnvironment: ''
        })

        setEducation({
            degree: '',
            field: '',
            university: '',
            place: '',
            experience: '',
            projects: '',
            information: '',
            responsibilities: '',
            toolAndSoftwares: '',
            approach: ''
        })
        setWorkExperiences([])
        setSkills([])
        setSkillsInfo({
            technicalSkills: '',
            softSkills: '',
            specialSkills: ''
        })


        setCertificates([])
        setLanguages([])
        setMemberships([])
        setVolunteerExperinces([])
        setAchievements([])
        setReferences([])

        setProblemSolving({
            challengingSituation: '',
            deadlines: '',
            qualityWork: ''
        })
        setTeamWork({
            teamProject: '',
            handleConflicts: '',
            communicationStyle: ''
        })
        setCustomerService({
            customerSatisfaction: '',
            experience: '',
            strategies: ''
        })

        setAdaptibility({
            adaptToChanges: '',
            learningSituation: '',
            careerGoals: ''
        })
        setCompanyMotivation({
            interests: '',
            contributions: '',
            fitForPosition: ''
        })
        setAdditionalQuestions({
            softwareProficiency: '',
            awardsAndRecognition: '',
            problemSolving: '',
            adaptabilityAndInitiative: '',
            continuousLearning: '',
            coreStrengths: '',
            achievements: ''
        })
    }
    const getIdForRecruiter = async () => {
        try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recruiter/getlast`)
            let incrementedId=1
            let paddedId="1"
            if(response.data){
                incrementedId = parseInt(response.data.id, 10) + 1;
                paddedId = String(incrementedId).padStart(response.data.id.length, '0');
            }
            return paddedId
        } catch (error) {
            console.log(error.message);
            return false
        }
    }

    const handleCreateNewRecruiterSetup=async()=>{
        setPassportError(false)
        refreshQuestionare()
        const paddId=await getIdForRecruiter()
        if(paddId){
            setNewRecruiterFormData(prev=>({
                ...prev,
                id:paddId
            }))
            setAddModal(true); 
            setIsUpdateRecruiter(false)     
        }
    }

    const handleSetupForUpdateRecuiter = async (recuiter) => {
        setPassportError(false)
        refreshQuestionare()
        setIsUpdateRecruiter(true)
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recruiter/getrecruiter/${recuiter._id}`);
        setQuestionaireSavedData(response.data.questionnaire)
        let recuiterSavedData = response.data
        delete recuiterSavedData.questionaire
        setNewRecruiterFormData(recuiterSavedData)
        setPaymentMethod(recuiterSavedData.paymentMethod)
        setAddModal(true)
    }


    React.useEffect(() => {
        sleep(200)
        if (!isUpdateRecruiter) {
            setQuestionaireSavedData({})
        }

    }, [newRecruiterFormData, isUpdateRecruiter])

    return (
        <div className='w-screen h-screen bg-gray-50'>
            <div className={`h-full absolute top-0 right-0 flex flex-col items-center pt-10 transition-all duration-500 ${sideBarStatus ? 'w-screen sm:w-[95%] md:w-[95%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%]' : 'w-screen sm:w-[95%] md:w-[95%] xl:w-[95%]'}`}>
                <div className='flex w-screen sm:w-[90%] lg:w-[80%] flex-row items-center justify-between gap-10'>
                    <h className='text-3xl font-bold'>Candidate</h>
                    <div className='flex flex-row items-center bg-gray-100 border-none border-gray-300 w-[50%] rounded-lg p-2'>
                        <input 
                            type='text' 
                            className='w-full h-full px-2 bg-transparent border-none rounded-lg outline-none' 
                            placeholder='Search...' 
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <img src={Search} className='w-6 h-6' alt='Search' />
                    </div>
                    <NavBar />
                </div>
                <div className=' w-screen sm:w-[90%] lg:w-[80%] mt-32 flex flex-col'>
                    <div className='flex flex-row justify-between w-full text-[#778CA2] font-semibold text-base items-center'>
                        <div className='flex flex-row gap-10'>
                            <div onClick={() => { handleCreateNewRecruiterSetup()}} className='flex flex-row gap-5 transition-all duration-200 cursor-pointer hover:scale-110'>
                                <span>Create Recruiter</span>
                                <img src={Add} className='w-5 h-5' />
                            </div>
                            <Modal
                                newRecruiterModelStep={addModal}
                                setNewRecruiterModelStep={setAddModal}
                                newRecruiterFormData={newRecruiterFormData}
                                setNewRecruiterFormData={setNewRecruiterFormData}
                                setNextStep={setQuestionareModal}
                                setThirdStep={setAddRecruiterConfirmModal}
                                setPaymentMethod={setPaymentMethod}
                                paymentMethod={paymentMethod}
                                questionaire={questionaire}
                                setRecruter={setRecruter}
                                setIsUpdateRecruiter={setIsUpdateRecruiter}
                                isUpdateRecruiter={isUpdateRecruiter}
                                passportError={passportError}
                                setPassportError={setPassportError}

                            />
                            <EditModal
                                newRecruiterModelStep={editModal}
                                setNewRecruiterModelStep={setEditModal}
                                newRecruiterFormData={recruiterFormData}
                                setNewRecruiterFormData={setRecruiterFormData}
                                setNextStep={setQuestionareModal}
                                questionaire={questionaire}
                                update={update} setUpdate={setUpdate}
                                setRecruter={setRecruter}
                                setIsEdit={setIsEdit}
                                setIsUpdateRecruiter={setIsUpdateRecruiter}
                                isUpdateRecruiter={isUpdateRecruiter}


                            />
                            <RecruterQuestionaireModel
                                newRecruiterModelStep={questionareModel}
                                setAddModal={setAddModal}
                                setNewRecruiterModelStep={setQuestionareModal}
                                newRecruiterFormData={newRecruiterFormData}
                                setNewRecruiterFormData={setNewRecruiterFormData}
                                setQuestionaire={setQuestionaire}
                                setRecruter={setRecruter}
                                setEditModal={setEditModal}
                                isEdit={isEdit}
                                questionaireSavedData={questionaireSavedData}
                                setIsUpdateRecruiter={setIsUpdateRecruiter}
                                isUpdateRecruiter={isUpdateRecruiter}
                                // Pass state variables and their setters as props
                                contactDetails={contactDetails}
                                setContactDetails={setContactDetails}
                                careerGoals={careerGoals}
                                setCareerGoals={setCareerGoals}
                                education={education}
                                setEducation={setEducation}
                                workExperiences={workExperiences}
                                setWorkExperiences={setWorkExperiences}
                                skills={skills}
                                setSkills={setSkills}
                                skillsInfo={skillsInfo}
                                setSkillsInfo={setSkillsInfo}
                                certificates={certificates}
                                setCertificates={setCertificates}
                                languages={languages}
                                setLanguages={setLanguages}
                                memberships={memberships}
                                setMemberships={setMemberships}
                                volunteerExperinces={volunteerExperinces}
                                setVolunteerExperinces={setVolunteerExperinces}
                                achievements={achievements}
                                setAchievements={setAchievements}
                                references={references}
                                setReferences={setReferences}
                                problemSolving={problemSolving}
                                setProblemSolving={setProblemSolving}
                                teamWork={teamWork}
                                setTeamWork={setTeamWork}
                                customerService={customerService}
                                setCustomerService={setCustomerService}
                                adaptibility={adaptibility}
                                setAdaptibility={setAdaptibility}
                                companyMotivation={companyMotivation}
                                setCompanyMotivation={setCompanyMotivation}
                                additionalQuestions={additionalQuestions}
                                setAdditionalQuestions={setAdditionalQuestions}
                            />

                            <RecruterConfirmModel
                                newRecruiterModelStep={addRecruiterConfirmModal}
                                setNewRecruiterModelStep={setAddRecruiterConfirmModal}
                                newRecruiterFormData={newRecruiterFormData}
                                setNewRecruiterFormData={setNewRecruiterFormData}
                                setUpdate={setUpdate} update={update}
                                methodOfPayment={paymentMethod}
                                setRecruter={setRecruter}
                                setIsUpdateRecruiter={setIsUpdateRecruiter}
                                isUpdateRecruiter={isUpdateRecruiter}

                            />
                            <DocumentUploadForm
                                newRecruiterModelStep={documentModal}
                                setNewRecruiterModelStep={setDocumentModal}
                                newRecruiterFormData={recruiterFormData}
                                setUpdate={setUpdate} update={update}
                                setEditModal={setEditModal}
                                setRecruter={setRecruter}
                                isUpdateRecruiter={isUpdateRecruiter}
                            />
                            <BackgroundCheckFrom
                                newRecruiterModelStep={backgroundModal}
                                setNewRecruiterModelStep={setBackgroundModal}
                                newRecruiterFormData={recruiterFormData}
                                setBack={setDocumentModal}
                                setUpdate={setUpdate} update={update}
                                setRecruter={setRecruter}
                            />
                            <ApplicationCompletionForm
                                newRecruiterModelStep={completionModal}
                                setNewRecruiterModelStep={setCompletionModal}
                                newRecruiterFormData={recruiterFormData}
                                setNewRecruiterFormData={setRecruiterFormData}
                                setupdate={setUpdate} update={update}
                                setRecruter={setRecruter}
                            />
                            <CV
                                newRecruiterModelStep={newRecruiterModelStep}
                                setNewRecruiterModelStep={setNewRecruiterModelStep}
                                newRecruiterFormData={recruiterFormData}
                                setNewRecruiterFormData={setRecruiterFormData}
                                setRecruter={setRecruter}
                            />
                            <DocsDownload
                                showDocDownload={showDocDownload}
                                recruiterData={selectedRecruiter}
                                onClose={() => setShowDocDownload(false)}
                                setRecruter={setRecruter}
                            />

                            <div onClick={() => exportToExcel(allRecruters)} className='flex flex-row gap-5 transition-all duration-200 cursor-pointer hover:scale-110'>
                                <span>Download</span>
                                <img src={Download1} className='w-5 h-5' />
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-10'>
                            <input
                                type='date'
                                className="p-2 px-4 font-bold text-white bg-[#CA9731] bg-opacity-70 rounded hover:bg-[#CA9731]"
                                onChange={(e) => {
                                    setSelectedDate(e.target.value);
                                }}
                            />
                            <div className='flex flex-row gap-3'>
                                <div className='flex flex-row cursor-pointer'>
                                    <span>Filter: </span>
                                    <select className='text-black cursor-pointer'>
                                        <option>All</option>
                                        <option>All</option>
                                        <option>All</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-3 mt-10'>
                        <div className={`grid w-full gap-5 font-semibold  text-center ${recruitersStatus === 'Active' ? 'grid-cols-6 ' : 'grid-cols-5'}`}>
                            <span className={`${recruitersStatus === 'Active' ? 'col-span-2' : 'col-span-1'}`}>Actions</span>
                            <span>Last Edited</span>
                            <span>Date Created</span>
                            <span>ID</span>
                            <span>User Name</span>

                        </div>
                        {selectedRecruiter == null
                            ? <div></div>
                            : <TransferModal transferModal={transferModal} setTransferModal={setTransferModal} recruiterId={selectedRecruiter._id} />}
                        {selectedRecruiterDetails == null
                            ? <div></div>
                            : <TransferDetails transferModal={transferDetails} setTransferModal={setTransferDetails} recruiterId={selectedRecruiterDetails._id} />}
                            <div className='flex flex-col w-full h-full gap-5 '>
                                {recrutersToDisplay
                                    .filter((recruter) => {
                                        // Only show recruiters in the relevant section based on their status
                                        if (recruitersStatus === "Active" && recruter.status === "Active") {
                                            return true;
                                        }
                                        if (recruitersStatus === "Inactive" && recruter.status === "Inactive") {
                                            return true;
                                        }
                                        return false;
                                    })
                                    .map((recruter) => (
                                        <div className={`grid items-center rounded-lg shadow-md gap-5 py-5 text-center shadow-inner 
                                            ${recruitersStatus === "Active"
                                                ? `grid-cols-6 
                                                ${recruter.informationProgress === 'basic' ? 'bg-[#FFF500]' :
                                                    recruter.informationProgress === 'documents' ? 'bg-[#FFA800] bg-opacity-65' :
                                                        recruter.informationProgress === 'background' ? 'bg-[#52FF00] bg-opacity-25' : 'bg-[#26CF86]'}`
                                                : 'bg-[#F03738] bg-opacity-[25%] grid-cols-5 cursor-pointer'
                                            }
                                            `} onClick={
                                                recruitersStatus === "Inactive"
                                                    ? () => { setSelectedRecruiterDetails(recruter); setTransferDetails(true); }
                                                    : () => { }
                                            }>
                                            <div className={`flex flex-row items-center justify-center gap-5 ${recruitersStatus === 'Active' ? 'col-span-2' : 'col-span-1'}`}>
                                                <img onClick={() => downloadCV(recruter.email, recruter.firstName + "_CV.pdf")} src={Download} className='w-5 h-5 transition-all duration-200 cursor-pointer hover:scale-125' />
                                                <img onClick={() => { setSelectedRecruiter(recruter); setShowDocDownload(true); }} src={Document} className='w-5 h-5 transition-all duration-200 cursor-pointer hover:scale-125' />
                                                <img onClick={
                                                    recruitersStatus === "Inactive"
                                                        ? () => { }
                                                        : () => { setRecruter(recruter); }
                                                } src={Edit} className='w-5 h-5 transition-all duration-200 cursor-pointer hover:scale-125' />
                                                <div
                                                    onClick={() => { setTransferModal(true); setSelectedRecruiter(recruter); }}
                                                    className={`flex-col justify-center ml-10 items-center cursor-pointer 
                                                        ${recruitersStatus === 'Active' ? 'flex' : 'hidden'}
                                                        ${userRole !== 'admin' ? 'hidden' : 'block'}
                                                    `}>
                                                    <span className='text-sm font-semibold'>Transfer</span>
                                                    <img src={ADD} className='w-5 h-5' />
                                                </div>
                                            </div>
                                            <span>{format(new Date(recruter.dateEdited), 'dd-MM-yyyy')}</span>
                                            <span>{format(new Date(recruter.dateCreated), 'dd-MM-yyyy')}</span>
                                            <span>{recruter.id}</span>
                                            <div className='flex flex-row items-center justify-end gap-3 mr-5'>
                                                <span>{recruter.firstName} {recruter.lastName}</span>
                                                <img src={recruter.profileImage ? recruter.profileImage : Profile} onClick={() => { handleSetupForUpdateRecuiter(recruter) }} className='w-10 h-10 rounded-full transition-all duration-200 cursor-pointer hover:scale-125' />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    <div className="flex justify-start mt-4">
                        <div
                            onClick={currentPage === 1 ? null : () => setCurrentPage(currentPage - 1)}
                            className={`flex flex-row gap-1 mt-1 ml-2 cursor-pointer ${currentPage === 1 ? 'opacity-50' : ''}`} 
                        >
                            <img src={Arrow} className='w-3 h-2 mt-[11px] rotate-90' />
                            <span>prev</span>
                        </div>
                        {getPageNumbers().map((pageNumber, index) => (
                            <button
                                key={index}
                                onClick={() => handlePageChange(pageNumber)}
                                className={`mx-2 ${currentPage === pageNumber ? "bg-[#CA9731] text-white rounded py-1 px-3" : " text-black rounded py-1 px-3"
                                    } rounded`}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <div
                            onClick={currentPage === totalPages ? null : () => setCurrentPage(currentPage + 1)}
                            className={`flex flex-row gap-1 mt-1 ml-2 cursor-pointer ${currentPage === totalPages ? 'opacity-50' : ''}`} 
                        >
                            <span>next</span>
                            <img src={Arrow} className='w-3 h-2 mt-[10px] -rotate-90' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recruters;


