import React, { useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import Cross from '../../Resources/Icons/x.png';

const Modal = ({ showModal, setShowModal, type, update, setUpdate }) => {
    const [formData, setFormData] = useState({
        title: '',
        time: '',
        date: '',
        description: '',
        phone: '',
        email: '',
        name: '',
        surname: '',
        companyName: '',
        businessNumber: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddAppointment = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/appointment/addappointment`, { formData, type });
            setUpdate(!update);
            setShowModal(false);
            toast.success('Appointment added successfully');
        } catch (error) {
            console.error('Error adding appointment:', error.message);
            toast.error('Failed to add appointment');
        }
    };

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-5 p-5 border-b border-solid rounded-t border-blueGray-200">
                                <h3 className="text-2xl font-semibold">Add Appointment</h3>
                                <img src={Cross} className='w-10 h-10 cursor-pointer' onClick={() => setShowModal(false)} alt="Close" />
                            </div>
                            <div className='flex flex-row px-10 py-5 bg-[#F8FAFB] gap-5'>
                                <div className='flex flex-col gap-3'>
                                    <div className='flex flex-col gap-3'>
                                        <label>Title of Appointment</label>
                                        <input name='title' onChange={handleInputChange} className={`p-2 border rounded ${type == 'organization' ? 'block' : 'hidden'}`} />
                                        <select name='title' onChange={handleInputChange} className={`p-2 border rounded ${type == 'organization' ? 'hidden' : 'block'}`}>
                                            <option value='' disabled selected>Select an option</option>
                                            <option value='New Employee Registration'>New Employee Registration</option>
                                            <option value='Document Expertise'>Document Expertise</option>
                                            <option value='Immigration Consultant'>Immigration Consultant</option>
                                        </select>
                                    </div>
                                    <div className='flex gap-3'>
                                        <div className='flex flex-col gap-1'>
                                            <label>Time</label>
                                            <input type='time' name='time' onChange={handleInputChange} className='p-2 border rounded' />
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <label>Date</label>
                                            <input type='date' name='date' onChange={handleInputChange} className='p-2 border rounded' placeholder="dd-mm-yyyy" min="1997-01-01" max="2030-12-31" />
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <label>Phone</label>
                                        <input type='text' name='phone' onChange={handleInputChange} className='p-2 border rounded' />
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <label>Email</label>
                                        <input type='text' name='email' onChange={handleInputChange} className='p-2 border rounded' />
                                    </div>
                                    <div className='flex flex-col gap-1'>
                                        <label>Description</label>
                                        <textarea name='description' onChange={handleInputChange} className='p-2 border rounded' />
                                    </div>
                                </div>
                                {type === 'person' ? (
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex flex-col gap-1'>
                                            <label>Name</label>
                                            <input type='text' name='name' onChange={handleInputChange} className='p-2 border rounded' />
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <label>Surname</label>
                                            <input type='text' name='surname' onChange={handleInputChange} className='p-2 border rounded' />
                                        </div>
                                    </ div>
                                ) : (
                                    <div className='flex flex-col gap-3'>
                                        <div className='flex flex-col gap-1'>
                                            <label>Company Name</label>
                                            <input type='text' name='companyName' onChange={handleInputChange} className='p-2 border rounded' />
                                        </div>
                                        <div className='flex flex-col gap-1'>
                                            <label>Business Number</label>
                                            <input type='text' name='businessNumber' onChange={handleInputChange} className='p-2 border rounded' />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex items-center justify-between p-4 border-t border-solid rounded-b border-blueGray-200">
                                <button
                                    className="px-10 py-2 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded"
                                    type="button"
                                    onClick={handleAddAppointment}
                                >
                                    Save
                                </button>
                                <button
                                    className="px-10 py-2 text-gray-500 bg-gray-200 rounded"
                                    type="button"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;

