import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import RecruiterContext from '../../Components/RecruterContext';
import NavBar from '../../Components/NavBar';
import Search from '../../Resources/Icons/search.png';
import Calendar from './Calendar';
import personBlack from '../../Resources/Icons/person.png';
import OrganizationBlack from '../../Resources/Icons/icons8-organization-90.png';
import OrganizationWhite from '../../Resources/Icons/organization-white.png';
import personWhite from '../../Resources/Icons/person-white.png';
import Back from '../../Resources/Icons/icons8-back-52.png';

const Appointments = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
        }
    }, []);

    const { sideBarStatus } = useContext(RecruiterContext);
    const [type, setType] = useState('');
    const [personHovered, setPersonHovered] = useState(false);
    const [orgHovered, setOrgHovered] = useState(false);

    return (
        <div className='w-screen min-h-screen bg-gray-50'>
            <div className={`min-h-fit absolute top-24 sm:top-0 right-0 flex flex-col items-center pt-10 transition-all duration-500 ${sideBarStatus ? 'w-screen sm:w-[95%] md:w-[95%] lg:w-[80%] xl:w-[85%] 2xl:w-[85%]' : 'w-screen sm:w-[95%] md:w-[95%] xl:w-[95%]'}`}>
                <div className='flex sm:w-[80%] lg:w-[80%] w-[95%] flex-row items-center justify-between gap-10'>
                    <h1 className='text-3xl font-bold'>Appointments</h1>
                    <div className='flex flex-row items-center bg-gray-100 border-none border-gray-300 w-[50%] rounded-lg p-2'>
                        <input type='text' className='w-full h-full px-2 bg-transparent border-none rounded-lg outline-none' placeholder='Search...' />
                        <img src={Search} className='w-6 h-6' alt='Search' />
                    </div>
                    <div className='hidden sm:block'>
                        <NavBar />
                    </div>
                </div>
                <div className={`sm:w-[80%] lg:w-[80%] w-[95%] mt-10 sm:mt-0 min-h-screen gap-20 flex-row px-10 items-center justify-center align-middle ${type === '' ? 'flex' : 'hidden'}`}>
                    <div
                        onMouseEnter={() => setPersonHovered(true)}
                        onMouseLeave={() => setPersonHovered(false)}
                        onClick={() => setType('person')}
                        className='transition-all duration-300 shadow-lg cursor-pointer hover:scale-105'
                    >
                        <div className='flex flex-col items-center justify-center gap-10 px-16 py-10 pb-20 text-center rounded-lg shadow-inner bg-white hover:bg-[#FDCF8B]'>
                            <h1 className='text-xl font-bold'>Person</h1>
                            <div className='relative w-40 h-40'>
                                <img
                                    src={personBlack}
                                    className={`absolute top-0 left-0 w-full h-full ${personHovered ? 'opacity-0' : 'opacity-100'}`}
                                    alt='Person'
                                />
                                <img
                                    src={personWhite}
                                    className={`absolute top-0 left-0 w-full h-full ${personHovered ? 'opacity-100' : 'opacity-0'}`}
                                    alt='Person'
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => setType('organization')}
                        className='transition-all duration-300 shadow-lg cursor-pointer hover:scale-105'
                    >
                        <div
                            onMouseEnter={() => setOrgHovered(true)}
                            onMouseLeave={() => setOrgHovered(false)}
                            className='py-10 pb-20 px-16 justify-center flex flex-col rounded-lg shadow-inner text-center items-center bg-white hover:bg-[#FDCF8B] gap-10'
                        >
                            <h1 className='text-xl font-bold'>Organization</h1>
                            <div className='relative w-40 h-40'>
                                <img
                                    src={OrganizationBlack}
                                    className={`absolute top-0 left-0 w-full h-full ${orgHovered ? 'opacity-0' : 'opacity-100'}`}
                                    alt='Organization'
                                />
                                <img
                                    src={OrganizationWhite}
                                    className={`absolute top-0 left-0 w-full h-full ${orgHovered ? 'opacity-100' : 'opacity-0'}`}
                                    alt='Organization'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`h-full w-full items-center align-middle mt-20 min-h-fit flex justify-center ${type === '' ? 'hidden' : 'block'}`}>
                    <CalendarPage type={type} setType={setType} />
                </div>
            </div>
        </div>
    );
};

const CalendarPage = ({ type, setType }) => {
    const [history, setHistory] = useState(true);

    return (
        <div className='sm:w-[80%] lg:w-[80%] w-[95%] mt-10 sm:mt-5 min-h-screen h-full flex flex-col px-10 pb-10'>
            <div className='flex flex-col gap-5 md:flex-row justify-between w-full text-[#778CA2] font-semibold text-base items-start md:items-center'>
                <div className='flex flex-row justify-between gap-5 text-sm lg:gap-10 lg:text-base sm:justify-between'>
                    <button onClick={() => setType('')} className='cursor-pointer w-20 text-white bg-[#CA9731] shadow-md rounded-lg border-none border-gray-200 rounded '>
                        Back
                    </button>
                    <button
                        onClick={() => setHistory(!history)}
                        className={`px-20 py-2 text-xs font-semibold bg-white shadow-md text-black border-bold border-1 border-black rounded-lg hover:bg-[#CA9731] hover:border-none hover:text-white transition-all duration-300 ${history ? 'bg-transparent border-black border-1' : 'bg-transparent border-black'}`}
                        >
                        History
                    </button>
                </div>
            </div>
            <div className='flex flex-col w-full gap-3 mt-10 overflow-auto rounded-lg'>
                <Calendar history={history} type={type} setType={setType} />
            </div>
        </div>
    );
};

export default Appointments;
