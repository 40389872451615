import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Cross from '../../Resources/Icons/x.png';
import RedCross from '../../Resources/Icons/cross-red.png'
import Logo from '../../Resources/cv_logo.png';
import jsPDF from 'jspdf';
import EmailLogo from "../../Resources/Email.png"
import PhoneLogo from "../../Resources/Phone.png"
import LocationLogo from "../../Resources/Location.png"

import LanguagesData from '../../Resources/Languages.json'


const Modal = ({
    isUpdateRecruiter,
    setIsUpdateRecruiter,
    questionaireSavedData,
    newRecruiterModelStep,
    setNewRecruiterModelStep,
    setAddModal,
    newRecruiterFormData,
    setNewRecruiterFormData,
    setQuestionaire,
    isEdit,
    setEditModal,
    // Additional props for newRecruiterFormData and setQuestionaire
    contactDetails,
    setContactDetails,
    careerGoals,
    setCareerGoals,
    education,
    setEducation,
    workExperiences,
    setWorkExperiences,
    skills,
    setSkills,
    skillsInfo,
    setSkillsInfo,
    certificates,
    setCertificates,
    languages,
    setLanguages,
    memberships,
    setMemberships,
    volunteerExperinces,
    setVolunteerExperinces,
    achievements,
    setAchievements,
    references,
    setReferences,
    problemSolving,
    setProblemSolving,
    teamWork,
    setTeamWork,
    customerService,
    setCustomerService,
    adaptibility,
    setAdaptibility,
    companyMotivation,
    setCompanyMotivation,
    additionalQuestions,
    setAdditionalQuestions
}) => {


    const [step, setStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');


    // const [contactDetails, setContactDetails] = useState({
    //     completeAddress: '',
    //     city: '',
    //     postalCode: '',
    //     province: '',
    //     country: '',
    //     email: '',
    //     phoneNumber: '',
    //     fixNumber: '',
    // });

    // const [careerGoals, setCareerGoals] = useState({
    //     targetJob: '',
    //     industry: '',
    //     workEnvironment: ''
    // });

    // const [education, setEducation] = useState({
    //     degree: '',
    //     field: '',
    //     university: '',
    //     place: '',
    //     experience: '',
    //     projects: '',
    //     information: '',
    //     responsibilities: '',
    //     toolAndSoftwares: '',
    //     approach: ''
    // });

    // const [workExperiences, setWorkExperiences] = useState([]);

    // const [skills, setSkills] = useState([]);
    // const [skillsInfo, setSkillsInfo] = useState({
    //     technicalSkills: '',
    //     softSkills: '',
    //     specialSkills: ''
    // });

    // const [certificates, setCertificates] = useState([]);
    // const [languages, setLanguages] = useState([]);
    // const [memberships, setMemberships] = useState([]);
    // const [volunteerExperinces, setVolunteerExperinces] = useState([]);
    // const [achievements, setAchievements] = useState([]);
    // const [references, setReferences] = useState([]);

    // const [problemSolving, setProblemSolving] = useState({
    //     challengingSituation: '',
    //     deadlines: '',
    //     qualityWork: ''
    // });

    // const [teamWork, setTeamWork] = useState({
    //     teamProject: '',
    //     handleConflicts: '',
    //     communicationStyle: ''
    // });

    // const [customerService, setCustomerService] = useState({
    //     customerSatisfaction: '',
    //     experience: '',
    //     strategies: ''
    // });

    // const [adaptibility, setAdaptibility] = useState({
    //     adaptToChanges: '',
    //     learningSituation: '',
    //     careerGoals: ''
    // });

    // const [companyMotivation, setCompanyMotivation] = useState({
    //     interests: '',
    //     contributions: '',
    //     fitForPosition: ''
    // });

    // const [additionalQuestions, setAdditionalQuestions] = useState({
    //     softwareProficiency: '',
    //     awardsAndRecognition: '',
    //     problemSolving: '',
    //     adaptabilityAndInitiative: '',
    //     continuousLearning: '',
    //     coreStrengths: '',
    //     achievements: ''
    // });
    let counter = 0

    // React.useEffect(()=>{
    //     alert(counter)
    //     if(counter==0 &&  !isUpdateRecruiter){
    //         refreshQuestionare()
    //     }
    //     counter++
    // },[])


    React.useEffect(() => {
        if (questionaireSavedData) {
            if (questionaireSavedData.contactDetails) {
                setContactDetails(questionaireSavedData.contactDetails);
            }
            if (questionaireSavedData.careerGoals) {
                setCareerGoals(questionaireSavedData.careerGoals);
            }
            if (questionaireSavedData.education) {
                setEducation(questionaireSavedData.education);
            }
            if (questionaireSavedData.workExperiences) {
                setWorkExperiences(questionaireSavedData.workExperiences);
            }
            if (questionaireSavedData.skills) {
                setSkills(questionaireSavedData.skills);
            }
            if (questionaireSavedData.skillsInfo) {
                setSkillsInfo(questionaireSavedData.skillsInfo);
            }
            if (questionaireSavedData.certificates) {
                setCertificates(questionaireSavedData.certificates);
            }
            if (questionaireSavedData.languages) {
                setLanguages(questionaireSavedData.languages);
            }
            if (questionaireSavedData.memberships) {
                setMemberships(questionaireSavedData.memberships);
            }
            if (questionaireSavedData.volunteerExperinces) {
                setVolunteerExperinces(questionaireSavedData.volunteerExperinces);
            }
            if (questionaireSavedData.achievements) {
                setAchievements(questionaireSavedData.achievements);
            }
            if (questionaireSavedData.references) {
                setReferences(questionaireSavedData.references);
            }
            if (questionaireSavedData.problemSolving) {
                setProblemSolving(questionaireSavedData.problemSolving);
            }
            if (questionaireSavedData.teamWork) {
                setTeamWork(questionaireSavedData.teamWork);
            }
            if (questionaireSavedData.customerService) {
                setCustomerService(questionaireSavedData.customerService);
            }
            if (questionaireSavedData.adaptibility) {
                setAdaptibility(questionaireSavedData.adaptibility);
            }
            if (questionaireSavedData.companyMotivation) {
                setCompanyMotivation(questionaireSavedData.companyMotivation);
            }
            if (questionaireSavedData.additionalQuestions) {
                setAdditionalQuestions(questionaireSavedData.additionalQuestions);
            }
        }

    }, [isUpdateRecruiter, questionaireSavedData])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const generateCVTemp = async (contactDetails1) => {
        let recruiterName = newRecruiterFormData.firstName + " " + newRecruiterFormData.lastName
        let contactDetails = [contactDetails1.email, contactDetails1.phoneNumber, contactDetails1.completeAddress]
        let educationInfo = {
            first: {
                toDate: education.year,
                heading: education.university,
                subHeading: education.degree + " | " + education.field,
                addrerss: education.place
            },
            second: {
                toDate: education.year,
                heading: education.university,
                subHeading: education.degree + " | " + education.field,
                addrerss: education.place
            }
        }


        let experienceInfo = {
        }
        if (workExperiences.length > 0) {

            experienceInfo = {
                first: {
                    fromDate: workExperiences[0].startDate.split("-")[0],
                    toDate: workExperiences[0]?.endDate?.split("-")[0],
                    heading: workExperiences[0].company,
                    subHeading: workExperiences[0].title,
                    addrerss: workExperiences[0].location,
                    currentlyWorking: workExperiences[0].currentlyWorking
                },
            }
        }
        if (workExperiences.length > 1) {
            experienceInfo = {
                ...experienceInfo,
                second: {
                    fromDate: workExperiences[1].startDate.split("-")[0],
                    toDate: workExperiences[1]?.endDate?.split("-")[0],
                    heading: workExperiences[1].company,
                    subHeading: workExperiences[1].title,
                    addrerss: workExperiences[1].location,
                    currentlyWorking: workExperiences[1].currentlyWorking
                }
            }
        }
        let remainingExperiences = []
        if (workExperiences.length > 2) {
            for (let i = 2; i < workExperiences.length; i++) {
                remainingExperiences.push(workExperiences[i])
            }
            remainingExperiences = remainingExperiences.map(workExperience => {
                return {
                    fromDate: workExperience.startDate.split("-")[0],
                    toDate: workExperience?.endDate?.split("-")[0],
                    heading: workExperience.company,
                    subHeading: workExperience.title,
                    addrerss: workExperience.location,
                    currentlyWorking: workExperience.currentlyWorking

                }
            })
        }


        function getLanageValue(strLevel) {
            if (strLevel == "0")
                return 0

            if (strLevel == "A1/A2")
                return 40
            if (strLevel == "B1/B2")
                return 70
            if (strLevel == "C1/C2")
                return 100

            if (strLevel == "native")
                return 100



        }

        let processedLanguages = languages.map(lang => ({ name: lang.name, val: getLanageValue(lang.proficiency), label: lang.proficiency }))


        // Create a new jsPDF instance
        const doc = new jsPDF('p', 'pt', 'a4');

        // Set page properties
        let pageWidth = doc.internal.pageSize.getWidth();
        let pageHeight = doc.internal.pageSize.getHeight();

        // Draw orange rectangle at the top for background color
        doc.setFillColor('#cb9731');
        doc.rect(0, 0, pageWidth, pageHeight * 0.1, 'F');

        // Draw a white circle behind the logo
        const circleRadius = 63; // Adjust circle radius as needed
        const circleX = (pageWidth - circleRadius * 2) / 2;
        const circleY = pageHeight * 0.070 - circleRadius; // Position above the logo
        doc.setFillColor('#FFFFFF'); // Set color to white
        doc.circle(circleX + circleRadius - 1, circleY + circleRadius + 28, circleRadius, 'F');

        // Insert logo image (replace 'Logo' with your image import)
        let logo = new Image();
        logo.src = Logo; // Provide the path to your logo
        await sleep(300); // Assuming sleep function is defined elsewhere
        let logoWidth = 330; // Adjust logo width as needed
        let logoHeight = 105; // Adjust logo height as needed
        let logoX = (pageWidth - logoWidth) / 2;
        doc.addImage(logo, 'png', logoX, pageHeight * 0.1 - 50, logoWidth, logoHeight);

        // Add 'RECRUITER NAME' heading centered on the page
        const usedColor = "#cb9731"

        doc.setFontSize(20);
        doc.setFont('helvetica', 'normal');
        doc.text("Resume", pageWidth / 2, pageHeight * 0.255, { align: 'center' });
        doc.setFont('helvetica', 'normal');


        doc.setFontSize(30);
        doc.setFont('helvetica', 'bold');
        doc.text(recruiterName, pageWidth / 2, pageHeight * 0.35, { align: 'center' });
        doc.setFont('helvetica', 'normal');

        // // Add 'CONTACT DETAILS' heading centered on the page
        // doc.setFontSize(20);
        // doc.setTextColor(usedColor);
        // doc.text('CONTACT DETAILS', pageWidth / 2, pageHeight * 0.43 - 10, { align: 'center' });
        // doc.setTextColor(0);






        // Calculate section width and margin
        const sectionWidth = pageWidth * 0.4;
        const margin = (pageWidth - sectionWidth * 2) / 2; // Center-align the sections

        // Manually specify positions for sections
        const sectionY = pageHeight * 0.52; // Start Y position for both sections

        const leftPadding = 55
        const topPadding = 10





        doc.setTextColor(0);
        // Section 1: Left section
        let yTC = sectionY - 70
        const section1X = margin + leftPadding;
        doc.setFontSize(18);
        doc.setFont('helvetica', 'normal');
        doc.text('Contact Details', section1X, yTC);
        doc.setFontSize(12);

        doc.setFont('helvetica', 'normal');
        yTC += 25
        doc.setTextColor(0);

        doc.setFontSize(11);

        let educationHeadingC = contactDetails[0];

        let logoImg = new Image();
        logoImg.src = EmailLogo;
        await sleep(300);
        let logoImageWidth = 15;
        let logoImageHeight = 15;
        let logoImageX = section1X;
        doc.addImage(logoImg, 'png', logoImageX, yTC - 12, logoImageWidth, logoImageHeight);

        doc.text(educationHeadingC, section1X + 20, yTC, { maxWidth: sectionWidth - 20 });

        yTC += 20


        educationHeadingC = contactDetails[1];
        logoImg = new Image();
        logoImg.src = PhoneLogo;
        await sleep(300);
        logoImageWidth = 15;
        logoImageHeight = 15;
        logoImageX = section1X;
        doc.addImage(logoImg, 'png', logoImageX, yTC - 12, logoImageWidth, logoImageHeight);

        doc.text(educationHeadingC, section1X + 20, yTC, { maxWidth: sectionWidth - 20 });

        doc.setFontSize(10);


        let educationTextC = contactDetails[2];

        let textLinesC = doc.splitTextToSize(educationTextC, sectionWidth - 20);
        let textHeightC = textLinesC.length * 10;

        let yC = yTC + 20

        logoImg = new Image();
        logoImg.src = LocationLogo;
        await sleep(300);
        logoImageWidth = 15;
        logoImageHeight = 15;
        logoImageX = section1X;
        doc.addImage(logoImg, 'png', logoImageX, yC - 12, logoImageWidth, logoImageHeight);

        doc.text(
            educationTextC,
            section1X + 20,
            yC,
            { maxWidth: sectionWidth - 20 }
        );

        function drawCircularImage(doc, img, x, y, width, height) {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Set canvas dimensions
            canvas.width = width;
            canvas.height = height;

            // Draw circular clipping path
            context.fillStyle = 'white';
            context.fillRect(0, 0, width, height);

            context.beginPath();
            context.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, true);
            context.closePath();
            context.clip();

            // Draw image
            context.drawImage(img, 0, 0, width, height);

            // Convert canvas to data URL
            const dataURL = canvas.toDataURL('image/jpeg');

            // Add the clipped image to the PDF
            doc.addImage(dataURL, 'JPEG', x, y, width, height);
        }

        // logoImg = new Image();
        // logoImg.src = UserLogo;
        // await sleep(300);
        logoImageWidth = 90;
        logoImageHeight = 90;
        logoImageX = section1X;
        // doc.addImage(logoImg, 'jpeg', logoImageX+230, yC - 80, logoImageWidth, logoImageHeight);
        if (newRecruiterFormData && newRecruiterFormData.image) {
            if (newRecruiterFormData.image instanceof File) {

                const file = newRecruiterFormData.image;
                const reader = new FileReader();
                const imagePromise = new Promise((resolve) => {
                    reader.onload = () => {
                        const img = new Image();
                        img.src = reader.result;
                        img.onload = () => resolve(img);
                    };
                });
                reader.readAsDataURL(file);
                let userImage = await imagePromise;
                drawCircularImage(doc, userImage, logoImageX + 250, yC - 90, logoImageWidth, logoImageHeight);
                doc.setFontSize(12);
            } else {

                const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/download-recruiter-profile-image/` + newRecruiterFormData.image;
                const imagePromise = new Promise((resolve) => {
                    const img = new Image();
                    img.crossOrigin = "Anonymous"; // Ensure the image is loaded with CORS
                    img.src = imageUrl;
                    img.onload = () => resolve(img);
                });
                let userImage = await imagePromise;
                drawCircularImage(doc, userImage, logoImageX + 250, yC - 90, logoImageWidth, logoImageHeight);
                doc.setFontSize(12);
            }
        }


        yC = yC + textHeightC + 20

        // doc.setFillColor(usedColor);
        // doc.setTextColor(255,255,255);
        // doc.rect(section1X, y-10, 85, 12, 'F');
        // doc.setFillColor(0,0,0);

        // doc.text(
        //     ""+educationInfo.second.toDate,
        //     section1X + 10,
        //     y,
        //     { maxWidth: sectionWidth - 20 }
        // );
        doc.setTextColor(0);


        yC += 20
        doc.setFontSize(10);
        educationHeadingC = educationInfo.second.heading;
        educationHeadingC = educationHeadingC.toUpperCase()
        // doc.text(educationHeading, section1X, y, { maxWidth: sectionWidth - 20 });

        yC += 15
        educationHeadingC = educationInfo.second.subHeading;
        // doc.text(educationHeading, section1X, y, { maxWidth: sectionWidth - 20 });


        educationTextC = educationInfo.second.addrerss;
        textLinesC = doc.splitTextToSize(educationTextC, sectionWidth - 20);
        textHeightC = textLinesC.length * 10;

        yC = yC + 15
        // doc.text(
        //     educationText,
        //     section1X,
        //     y, 
        //     { maxWidth: sectionWidth - 20 }
        // );

        doc.setTextColor(0);
        // Section 1: Left section
        let yT1 = sectionY + 35 + topPadding
        // const section1X = margin + leftPadding;
        doc.setFontSize(18);
        doc.setFont('helvetica', 'normal');
        doc.text('EDUCATION', section1X, yT1);
        doc.setFontSize(12);

        doc.setFont('helvetica', 'normal');
        doc.setFillColor(usedColor);
        doc.setTextColor(255, 255, 255);
        yT1 += 25
        doc.rect(section1X, yT1, 53, 12, 'F');
        doc.setFillColor(0, 0, 0);
        yT1 += 10
        doc.text(
            " " + educationInfo.first.toDate,
            section1X + 10,
            yT1,
            { maxWidth: sectionWidth - 20 }
        );
        doc.setTextColor(0);

        doc.setFontSize(11);
        let educationHeading = educationInfo.first.heading;
        educationHeading = educationHeading.toUpperCase()
        yT1 += 20
        doc.text(educationHeading, section1X, yT1, { maxWidth: sectionWidth - 20 });

        yT1 += 15
        educationHeading = educationInfo.first.subHeading;
        doc.text(educationHeading, section1X, yT1, { maxWidth: sectionWidth - 20 });

        doc.setFontSize(10);

        let educationText = educationInfo.first.addrerss;
        let textLines = doc.splitTextToSize(educationText, sectionWidth - 20);
        let textHeight = textLines.length * 10;

        let y = yT1 + 15

        doc.text(
            educationText,
            section1X,
            y,
            { maxWidth: sectionWidth - 20 }
        );

        doc.setFontSize(12);
        y = y + textHeight + 20

        // doc.setFillColor(usedColor);
        // doc.setTextColor(255,255,255);
        // doc.rect(section1X, y-10, 85, 12, 'F');
        // doc.setFillColor(0,0,0);

        // doc.text(
        //     ""+educationInfo.second.toDate,
        //     section1X + 10,
        //     y,
        //     { maxWidth: sectionWidth - 20 }
        // );
        doc.setTextColor(0);


        y += 20
        doc.setFontSize(10);
        educationHeading = educationInfo.second.heading;
        educationHeading = educationHeading.toUpperCase()
        // doc.text(educationHeading, section1X, y, { maxWidth: sectionWidth - 20 });

        y += 15
        educationHeading = educationInfo.second.subHeading;
        // doc.text(educationHeading, section1X, y, { maxWidth: sectionWidth - 20 });


        educationText = educationInfo.second.addrerss;
        textLines = doc.splitTextToSize(educationText, sectionWidth - 20);
        textHeight = textLines.length * 10;

        y = y + 15
        // doc.text(
        //     educationText,
        //     section1X,
        //     y, 
        //     { maxWidth: sectionWidth - 20 }
        // );


        doc.setTextColor(0);
        // Section 1: Left section
        let yT2 = sectionY + 35 + topPadding
        const section2X = margin + sectionWidth + leftPadding;
        doc.setFontSize(18);
        doc.setFont('helvetica', 'normal');
        doc.text('EXPERIENCE', section2X, yT2);
        doc.setFontSize(12);

        doc.setFont('helvetica', 'normal');
        doc.setFillColor(usedColor);
        doc.setTextColor(255, 255, 255);
        yT2 += 25
        doc.rect(section2X, yT2, 94, 12, 'F');
        doc.setFillColor(0, 0, 0);
        yT2 += 10
        doc.text(
            experienceInfo.first.fromDate + " - " + (experienceInfo.first.toDate ? experienceInfo.first.toDate : "Current"),
            section2X + 10,
            yT2,
            { maxWidth: sectionWidth - 20 }
        );
        doc.setTextColor(0);

        doc.setFontSize(11);
        educationHeading = experienceInfo.first.heading;
        educationHeading = educationHeading.toUpperCase()
        yT2 += 20
        doc.text(educationHeading, section2X, yT2, { maxWidth: sectionWidth - 20 });

        yT2 += 15
        educationHeading = experienceInfo.first.subHeading;
        doc.text(educationHeading, section2X, yT2, { maxWidth: sectionWidth - 20 });

        doc.setFontSize(10);

        educationText = experienceInfo.first.addrerss;
        textLines = doc.splitTextToSize(educationText, sectionWidth - 20);
        textHeight = textLines.length * 10;

        y = yT2 + 15

        doc.text(
            educationText,
            section2X,
            y,
            { maxWidth: sectionWidth - 20 }
        );

        //second experience

        doc.setFontSize(12);
        y = y + textHeight + 20

        if (experienceInfo.second) {
            doc.setFillColor(usedColor);
            doc.setTextColor(255, 255, 255);
            doc.rect(section2X, y - 10, 94, 12, 'F');
            doc.setFillColor(0, 0, 0);

        }


        if (experienceInfo.second) {
            doc.text(
                experienceInfo.second.fromDate + " - " + (experienceInfo.second.toDate ? experienceInfo.second.toDate : "Current"),
                section2X + 10,
                y,
                { maxWidth: sectionWidth - 20 }
            );
        }


        doc.setTextColor(0);


        y += 20
        doc.setFontSize(10);
        educationHeading = experienceInfo?.second?.heading;
        educationHeading = educationHeading?.toUpperCase()
        if (experienceInfo.second) {
            doc.text(educationHeading, section2X, y, { maxWidth: sectionWidth - 20 });
        }
        y += 15
        educationHeading = experienceInfo?.second?.subHeading;
        if (experienceInfo.second) {
            doc.text(educationHeading, section2X, y, { maxWidth: sectionWidth - 20 });
        }

        educationText = experienceInfo?.second?.addrerss;
        textLines = doc.splitTextToSize(educationText ? educationText : "", sectionWidth - 20);
        textHeight = textLines.length * 10;

        y = y + 15
        if (experienceInfo.second) {
            doc.text(
                educationText,
                section2X,
                y,
                { maxWidth: sectionWidth - 20 }
            );
        }









        function addLanguage(name, val, label) {
            doc.setTextColor(usedColor);
            doc.text(name, section1X, yS1);

            doc.text("_" + label, sectionWidth - 100 + leftPadding, yS1 - 7)
            doc.setFillColor(0, 0, 0);
            doc.rect(sectionWidth - 120 + leftPadding, yS1 - 3, totalLength, heightOfBar, 'F');

            //langaguge 1
            doc.setFillColor(usedColor);
            let langVal = val       //langayge value from form
            let calculatedLength = Math.round((langVal / 100) * totalLength)
            doc.rect(sectionWidth - 120 + leftPadding, yS1 - 3, calculatedLength, heightOfBar, 'F');
            doc.setFillColor(null);
        }

        let pageCount = 1
        function addNewPageSetupForLangSkil() {
            if ((yS1 > (0.98 * pageHeight)) || (yS2 > (0.98 * pageHeight))) {
                doc.addPage()
                yS1 = initialY
                yS2 = initialY
                addPageHead()
                pageCount++
            }
        }

        let initialY = pageHeight * 0.255
        let startY = initialY


        doc.setTextColor(0);
        let yS1 = y + 35
        doc.setFontSize(18);
        doc.setFont('helvetica', 'normal');
        if (languages.length > 0) {
            doc.text('LANGAUGE', section1X, yS1);

        }
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');


        doc.setTextColor(0);
        let yS2 = y + 35
        doc.setFontSize(18);
        doc.setFont('helvetica', 'normal');
        if (skills.length > 0) {
            doc.text('SKILLS', section2X, yS2);
        }
        doc.setFontSize(12);

        let num = 0

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(usedColor);
        yS2 += 30

        // skills.forEach(skill => {
        //     addSkill(skill)
        // })

        yS1 += 30
        let totalLength = 130
        let heightOfBar = 3

        let counter = 0
        let counterSkill = 0
        for (; counter < processedLanguages.length || counterSkill < skills.length; counter++) {
            addNewPageSetupForLangSkil()
            if (counterSkill < skills.length) {
                addSkill(skills[counterSkill])
                counterSkill++
            }
            if (counter < processedLanguages.length) {
                addLanguage(processedLanguages[counter].name, processedLanguages[counter].val, processedLanguages[counter].label)
                yS1 += 30
            }

        }

        // function addSkill(name) {
        //     if (name) {
        //         if (num % 2 == 0) {
        //             doc.text(name, section2X, yS2);
        //         } else {
        //             doc.text(name, section2X + 100, yS2);
        //             yS2 += 25
        //         }
        //         num++;
        //     }
        // }

        function addSkill(name) {
            doc.text(name, section2X, yS2);
            num++;
            yS2 += 25

        }
        function addExperience(fromDate, toDate, heading, subHeading, addrerss, yTempS2, section2X) {
            doc.setTextColor(0);
            doc.setFontSize(12);
            let yS2 = yTempS2

            doc.setFont('helvetica', 'normal');
            doc.setFillColor(usedColor);
            doc.setTextColor(255, 255, 255);
            yS2 += 25
            doc.rect(section2X, yS2, 94, 12, 'F');
            doc.setFillColor(0, 0, 0);
            yS2 += 10
            doc.text(
                fromDate + " - " + (toDate ? toDate : "Current"),
                section2X + 10,
                yS2,
                { maxWidth: sectionWidth - 20 }
            );
            doc.setTextColor(0);

            doc.setFontSize(11);
            educationHeading = heading;
            educationHeading = educationHeading.toUpperCase()
            yS2 += 20
            doc.text(educationHeading, section2X, yS2, { maxWidth: sectionWidth - 20 });

            yS2 += 15
            educationHeading = subHeading;
            doc.text(educationHeading, section2X, yS2, { maxWidth: sectionWidth - 20 });

            doc.setFontSize(10);

            educationText = addrerss;
            textLines = doc.splitTextToSize(educationText, sectionWidth - 20);
            textHeight = textLines.length * 10;

            yS2 = yS2 + 15

            doc.text(
                educationText,
                section2X,
                yS2,
                { maxWidth: sectionWidth - 20 }
            );
            doc.setFontSize(12);
            return yS2
        }
        doc.setTextColor(0);

        if (yS1 > yS2) {
            yS2 = yS1
        }
        let yActualSLeft = yS2 + 30
        let yActualSRight = yS2 + 30

        if (yS2 > (pageHeight)) {
            doc.addPage()
            yActualSRight = initialY
            yActualSLeft = initialY
            addPageHead()
            pageCount++

        }
        function addNewPageSetupForRemainingExperinces() {
            if ((remainingExperiences.length > 0) && (yActualSRight > (0.80 * pageHeight))) {
                doc.addPage()
                yActualSRight = initialY
                yActualSLeft = initialY
                addPageHead()
                pageCount++

            }
        }

        num = 0
        function addFormattedExperience(fromDate, toDate, heading, subHeading, addrerss) {
            if (num % 2 == 0) {
                yActualSLeft = addExperience(fromDate, toDate, heading, subHeading, addrerss, yActualSLeft, section1X)

            } else {
                yActualSRight = addExperience(fromDate, toDate, heading, subHeading, addrerss, yActualSRight, section2X)
            }
            num++;
        }
        addNewPageSetupForRemainingExperinces()
        if (remainingExperiences.length > 0) {
            doc.setFontSize(18);
            doc.setFont('helvetica', 'normal');
            doc.text('EXPERIENCE', section1X, yActualSLeft);
            doc.setFontSize(12);

            remainingExperiences.forEach(experienceInfo => {
                addNewPageSetupForRemainingExperinces()
                addFormattedExperience(experienceInfo.fromDate, experienceInfo.toDate, experienceInfo.heading, experienceInfo.subHeading, experienceInfo.addrerss)
                addNewPageSetupForRemainingExperinces()
            })
        }


        function addPageHead() {
            doc.setFillColor('#cb9731');
            doc.rect(0, 0, pageWidth, pageHeight * 0.1, 'F');

            // Draw a white circle behind the logo
            doc.setFillColor('#FFFFFF'); // Set color to white
            doc.circle(circleX + circleRadius - 1, circleY + circleRadius + 28, circleRadius, 'F');

            // Insert logo image (replace 'Logo' with your image import)
            doc.addImage(logo, 'png', logoX, pageHeight * 0.1 - 50, logoWidth, logoHeight);
        }
        function addNewPageSetup() {
            if (startY > (0.99 * pageHeight)) {
                doc.addPage()
                startY = initialY
                addPageHead()
                pageCount++
            }
        }
        doc.addPage()


        // Set page properties
        pageWidth = doc.internal.pageSize.getWidth();
        pageHeight = doc.internal.pageSize.getHeight();

        addPageHead()


        let qLeftSPACE = pageWidth * 0.06
        let qMaxRightSpace = pageWidth * 0.91
        let sampleYSpace = 80

        doc.setTextColor(0);
        doc.setFontSize(20);


        if (careerGoals.targetJob || careerGoals.industry || careerGoals.workEnvironment) {
            doc.setFontSize(18);
            doc.text("Career Goals", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()
        if (careerGoals.targetJob) {
            doc.setFontSize(15);
            doc.text("Target Job Title(s):", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()

            doc.setFontSize(12);
            let text = careerGoals.targetJob ? careerGoals.targetJob : "-"
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;

        }
        addNewPageSetup()
        if (careerGoals.industry) {
            doc.setFontSize(15);
            doc.text("Industry:", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = careerGoals.industry ? careerGoals.industry : "-";
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;

        }
        addNewPageSetup()
        if (careerGoals.workEnvironment) {
            doc.setFontSize(15);
            doc.text("Ideal Work Environment:", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = careerGoals.workEnvironment ? careerGoals.workEnvironment : "-";
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;
        }
        addNewPageSetup()

        if (education.experience || education.projects || education.information) {
            doc.setFontSize(18);
            doc.text("Experience and Skills", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (education.experience) {
            doc.setFontSize(15);
            doc.text("What specific experience do you have related to the [job position] you are applying for?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = education.experience;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;
        }
        addNewPageSetup()

        if (education.projects) {
            doc.setFontSize(15);
            doc.text("Can you provide examples of projects or tasks where you demonstrated [relevant skills]?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = education.projects;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;

        }
        addNewPageSetup()

        if (education.information) {
            doc.setFontSize(15);
            doc.text("How do you stay updated with industry trends and advancements?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = education.information;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;
        }
        addNewPageSetup()

        if (education.responsibilities || education.toolAndSoftwares || education.approach) {
            doc.setFontSize(18);

            doc.text("Job Specific Knowledge", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (education.responsibilities) {
            doc.setFontSize(15);
            doc.text("What do you understand about the responsibilities and requirements of this role?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = education.responsibilities;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;

        }
        addNewPageSetup()

        if (education.toolAndSoftwares) {
            doc.setFontSize(15);
            doc.text("How familiar are you with [specific tools/software] used in this industry?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = education.toolAndSoftwares;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;

        }
        addNewPageSetup()

        if (education.approach) {
            doc.setFontSize(15);
            doc.text("Can you explain your approach to [key aspect of the job]?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = education.approach;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;
        }
        addNewPageSetup()

        if (skillsInfo.technicalSkills || education.softSkills || education.specialSkills) {
            doc.setFontSize(18);
            doc.text("Skills", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (skillsInfo.technicalSkills) {
            doc.setFontSize(15);
            doc.text("What technical skills do you possess?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = skillsInfo.technicalSkills;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;
        }
        addNewPageSetup()

        if (skillsInfo.softSkills) {
            doc.setFontSize(15);
            doc.text("What soft skills do you excel in ?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = skillsInfo.softSkills;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;

        }
        addNewPageSetup()

        if (skillsInfo.specialSkills) {
            doc.setFontSize(15);
            doc.text("Are there any specialized skills relevant to the job you're applying for?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = skillsInfo.specialSkills;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7;
        }
        addNewPageSetup()


        if (certificates.length > 0) {
            doc.setFontSize(15);
            doc.text("Certifications", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            certificates.forEach(certificate => {
                doc.setFontSize(12);
                doc.text(certificate.name + "  |  " + certificate.organization + "  |  " + certificate.year, qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
                startY += 20

                addNewPageSetup()
            })
            startY += 35
        }
        addNewPageSetup()


        if (memberships.length > 0) {
            doc.setFontSize(15);
            doc.text("Professional Memberships", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            memberships.forEach(membership => {
                doc.setFontSize(12);
                doc.text(membership.name + "  |  " + membership.organization, qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
                startY += 20
                addNewPageSetup()
            })
            startY += 35
        }
        addNewPageSetup()


        if (volunteerExperinces.length > 0) {
            doc.setFontSize(15);
            doc.text("Volunteer Experience", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            volunteerExperinces.forEach(volunteerExperince => {
                doc.setFontSize(12);
                doc.text(volunteerExperince.startDate + "  -  " + volunteerExperince.startDate, qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
                startY += 20
                addNewPageSetup()
                doc.text(volunteerExperince.role + "  |  " + volunteerExperince.organization, qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
                startY += 20
                addNewPageSetup()
                doc.setFontSize(12);
                let text = volunteerExperince.description;
                let lines = doc.splitTextToSize(text, qMaxRightSpace);
                doc.text(lines, qLeftSPACE, startY);
                const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
                const textHeight = lines.length * lineHeight;
                startY += (textHeight + 5); // Adjusted as per your requirement
            })
            startY += 20
        }
        addNewPageSetup()

        if (achievements.length > 0 || references.length > 0) {
            doc.setFontSize(18);
            doc.text("Achievements and References", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
            addNewPageSetup()
        }
        if (achievements.length > 0) {
            doc.setFontSize(15);
            doc.text("Achievements", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            achievements.forEach(achievement => {
                doc.setFontSize(12);
                doc.text(achievement.name, qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
                startY += 20
                doc.setFontSize(12);
                let text = achievement.description;
                let lines = doc.splitTextToSize(text, qMaxRightSpace);
                doc.text(lines, qLeftSPACE, startY);
                const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
                const textHeight = lines.length * lineHeight;
                startY += textHeight + 7; // Adjusted as per your requirement
            })
            startY += 20
            addNewPageSetup()
        }
        addNewPageSetup()


        if (references.length > 0) {
            doc.setFontSize(15);
            doc.text("References", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            references.forEach(reference => {
                doc.setFontSize(12);
                doc.text(reference.name, qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
                startY += 20
                doc.setFontSize(12);
                let text = reference.description;
                let lines = doc.splitTextToSize(text, qMaxRightSpace);
                doc.text(lines, qLeftSPACE, startY);
                const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
                const textHeight = lines.length * lineHeight;
                startY += textHeight + 7; // Adjusted as per your requirement
            })
            startY += 20
            addNewPageSetup()
        }
        if (problemSolving.challengingSituation || problemSolving.deadlines || problemSolving.qualityWork) {
            doc.setFontSize(18);
            doc.text("Problem Solving And Decision Making", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (problemSolving.challengingSituation) {
            doc.setFontSize(15);
            doc.text("Describe a challenging situation you encountered at work and how you resolved it.", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = problemSolving.challengingSituation;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7; // Assuming sampleYSpace is the desired vertical spacing
        }
        addNewPageSetup()

        if (problemSolving.deadlines) {
            doc.setFontSize(15);
            doc.text("How do you prioritize tasks and manage deadlines effectively?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = problemSolving.deadlines;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Assuming sampleYSpace is the desired vertical spacing

        }
        addNewPageSetup()

        if (problemSolving.qualityWork) {
            doc.setFontSize(15);
            doc.text("What steps do you take to ensure quality and accuracy in your work?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = problemSolving.qualityWork;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Assuming sampleYSpace is the desired vertical spacing
        }
        addNewPageSetup()

        if (teamWork.teamProject || teamWork.handleConflicts || teamWork.communicationStyle) {
            doc.setFontSize(18);
            doc.text("Teamwork and Communication", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (teamWork.teamProject) {
            doc.setFontSize(15);
            doc.text("Give an example of a successful team project you were part of. What was your role, and how did you contribute?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = teamWork.teamProject;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Assuming sampleYSpace is the desired vertical spacing
        }
        addNewPageSetup()

        if (teamWork.handleConflicts) {
            doc.setFontSize(15);
            doc.text("How do you handle conflicts or disagreements within a team environment?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = teamWork.handleConflicts;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Assuming sampleYSpace is the desired vertical spacing

        }
        addNewPageSetup()

        if (teamWork.communicationStyle) {
            doc.setFontSize(15);
            doc.text("Describe your communication style and how you adapt it based on different stakeholders.", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = teamWork.communicationStyle;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Assuming sampleYSpace is the desired vertical spacing
        }
        addNewPageSetup()




        if (customerService.customerSatisfaction || customerService.experience || customerService.strategies) {
            doc.setFontSize(18);
            doc.text("Customer Service and Client Interaction", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (customerService.customerSatisfaction) {
            doc.setFontSize(15);
            doc.text("How do you approach customer/client satisfaction in your work?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = customerService.customerSatisfaction;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Assuming sampleYSpace is the desired vertical spacing
        }
        addNewPageSetup()

        if (customerService.experience) {
            doc.setFontSize(15);
            doc.text("Can you share an experience where you successfully addressed a customer's concern or request?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = customerService.experience;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement

        }
        addNewPageSetup()

        if (customerService.strategies) {
            doc.setFontSize(15);
            doc.text("What strategies do you use to build and maintain positive relationships with clients?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = customerService.strategies;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement
        }
        addNewPageSetup()




        if (adaptibility.adaptToChanges || adaptibility.learningSituation || adaptibility.careerGoals) {
            doc.setFontSize(18);
            doc.text("Adaptibility and Learning", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (adaptibility.adaptToChanges) {
            doc.setFontSize(15);
            doc.text("How do you adapt to changes or unexpected challenges in your work environment?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()
            doc.setFontSize(12);
            let text = adaptibility.adaptToChanges;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement
        }
        addNewPageSetup()

        if (adaptibility.learningSituation) {
            doc.setFontSize(15);
            doc.text("Describe a situation where you had to learn a new skill or technology quickly. How did you approach it?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()

            doc.setFontSize(12);
            let text = adaptibility.learningSituation;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement

        }
        addNewPageSetup()

        if (adaptibility.careerGoals) {
            doc.setFontSize(15);
            doc.text("What are your career goals, and how do you plan to achieve them?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = adaptibility.careerGoals;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement
        }
        addNewPageSetup()


        if (companyMotivation.interests || companyMotivation.contributions || companyMotivation.fitForPosition) {
            doc.setFontSize(18);
            doc.text("Company Fit and Motivation", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        if (companyMotivation.interests) {
            doc.setFontSize(15);
            doc.text("What interests you most about working for our company?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = companyMotivation.interests;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement
        }
        addNewPageSetup()

        if (companyMotivation.contributions) {
            doc.setFontSize(15);
            doc.text("How do you see yourself contributing to our team and achieving our company's goals?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 40
            addNewPageSetup()


            doc.setFontSize(12);
            let text = companyMotivation.contributions;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement

        }
        addNewPageSetup()

        if (companyMotivation.fitForPosition) {
            doc.setFontSize(15);
            doc.text("Why do you think you are the right fit for this position?", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = companyMotivation.fitForPosition;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10; // Adjusted as per your requirement
        }
        addNewPageSetup()



        if (additionalQuestions.softwareProficiency || additionalQuestions.problemSolving
            || additionalQuestions.adaptabilityAndInitiative || additionalQuestions.continuousLearning || additionalQuestions.coreStrengths || additionalQuestions.achievements
        ) {
            doc.setFontSize(18);
            doc.text("Additional Questions", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 35
        }
        addNewPageSetup()

        let extraY = 20
        if (additionalQuestions.softwareProficiency) {
            doc.setFontSize(15);
            doc.text("Software Proficiency:", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = additionalQuestions.softwareProficiency;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 10 + extraY; // Adjusted as per your requirement
        }
        addNewPageSetup()

        if (additionalQuestions.problemSolving) {
            doc.setFontSize(15);
            doc.text("Problem Solving", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = additionalQuestions.problemSolving;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7 + extraY; // Adjusted as per your requirement

        }
        addNewPageSetup()

        if (additionalQuestions.adaptabilityAndInitiative) {
            doc.setFontSize(15);
            doc.text("Adaptability & Initiative", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = additionalQuestions.adaptabilityAndInitiative;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7 + extraY; // Adjusted as per your requirement
        }
        addNewPageSetup()

        if (additionalQuestions.continuousLearning) {
            doc.setFontSize(15);
            doc.text("Continuous Learning", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()
            doc.setFontSize(12);
            let text = additionalQuestions.continuousLearning;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7 + extraY; // Adjusted as per your requirement

        }
        addNewPageSetup()


        if (additionalQuestions.coreStrengths) {
            doc.setFontSize(15);
            doc.text("Core Strengths", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()


            doc.setFontSize(12);
            let text = additionalQuestions.coreStrengths;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7 + extraY; // Adjusted as per your requirement

        }
        addNewPageSetup()


        if (additionalQuestions.achievements) {
            doc.setFontSize(15);
            doc.text("Achievements Showcase", qLeftSPACE, startY, { maxWidth: qMaxRightSpace });
            startY += 25
            addNewPageSetup()

            doc.setFontSize(12);
            let text = additionalQuestions.achievements;
            let lines = doc.splitTextToSize(text, qMaxRightSpace);
            doc.text(lines, qLeftSPACE, startY);
            const lineHeight = doc.getFontSize() * 1.2; // Assuming 1.2 line spacing
            const textHeight = lines.length * lineHeight;
            startY += textHeight + 7 + extraY; // Adjusted as per your requirement

        }
        addNewPageSetup()

        try {
            // Save the PDF as a blob
            const blob = doc.output('blob');
            doc.save(newRecruiterFormData.firstName + " " + newRecruiterFormData.lastName + '_CV.pdf');

            // Create FormData to send the PDF file
            const formData = new FormData();
            formData.append('pdfFile', blob, contactDetails1.email + '_CV.pdf');

            // Send the PDF file to the backend using fetch
            const url = `${process.env.REACT_APP_BACKEND_URL}/recruiter/CVs`;

            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            console.log('PDF file sent successfully to the backend.');

            // Handle success response from backend if needed
            const responseData = await response.json();
            console.log('Backend response:', responseData);
        } catch (error) {
            console.error('Error occurred while sending PDF file:', error);
            // Handle fetch error
        }
    };





















    const handleGenerateCV = async () => {
        try {
            generateCVTemp(contactDetails, education)
            console.log("*******************CV Data information***********************")
            let quetionnaireData = {
                contactDetails,
                careerGoals,
                education,
                workExperiences,
                skills,
                skillsInfo,
                certificates,
                languages,
                memberships,
                volunteerExperinces,
                achievements,
                references,
                problemSolving,
                teamWork,
                customerService,
                adaptibility,
                companyMotivation,
                additionalQuestions
            }
            if (isUpdateRecruiter) {
                quetionnaireData = { ...quetionnaireData, _id: questionaireSavedData._id }
            }
            setNewRecruiterFormData(prev => {
                return ({ ...prev, quetionnaireData })
            })


        } catch (error) {
            console.error('Error adding Recruiter', error.message);
        }
    };


    const handleNextStep = () => {
        if (step === 1) {
            if (!contactDetails.completeAddress || !contactDetails.city || !contactDetails.postalCode || !contactDetails.country || !contactDetails.email || !contactDetails.phoneNumber) {
                setErrorMessage('Please fill in all required fields.');
                return;
            }
        }
        if (step === 3) {
            if (!education.degree || !education.field || !education.university || !education.place || !education.year) {
                setErrorMessage('Please fill in the education details.');
                return;
            }
        }
        if (step === 6) {
            if (languages.length === 0) {
                setErrorMessage('Please add at least one language.');
                return;
            }
        }
        setErrorMessage('');
        setStep(prev => prev < 13 ? prev + 1 : 13);
    };

    return (
        <>
            {newRecruiterModelStep && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-20 p-5 px-10 border-b border-solid bg-[#FFF500] rounded-t ">
                                <h3 className="text-xl font-bold text-black">Questionare</h3>
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110'
                                    onClick={() => {
                                        if (isEdit == true) {
                                            setNewRecruiterModelStep(false);
                                            setEditModal(true);
                                        } else {
                                            setNewRecruiterModelStep(false);
                                            setAddModal(true);
                                        }

                                    }} />
                            </div>
                            <div>
                                {
                                    step == 1 ? <ContactDetails step={step} setStep={setStep} contactDetails={contactDetails} setContactDetails={setContactDetails} error={errorMessage} /> :
                                        step == 2 ? <CareerGoals step={step} setStep={setStep} careerGoals={careerGoals} setCareerGoals={setCareerGoals} /> :
                                            step == 3 ? <Education step={step} setStep={setStep} education={education} setEducation={setEducation} error={errorMessage} /> :
                                                step == 4 ? <WorkExperience step={step} setStep={setStep} workExperiences={workExperiences} setWorkExperiences={setWorkExperiences} /> :
                                                    step == 5 ? <Skills step={step} setStep={setStep} skills={skills} setSkills={setSkills} skillsInfo={skillsInfo} setSkillsInfo={setSkillsInfo} /> :
                                                        step == 6 ? <CertificationsAndLanguages step={step} setStep={setStep} certificates={certificates} setCertificates={setCertificates} languages={languages} setLanguages={setLanguages} memberships={memberships} setMemberships={setMemberships} volunteerExperinces={volunteerExperinces} setVolunteerExperinces={setVolunteerExperinces} error={errorMessage} /> :
                                                            step == 7 ? <AchievementsAndReferences step={step} setStep={setStep} achievements={achievements} setAchievements={setAchievements} references={references} setReferences={setReferences} /> :
                                                                step == 8 ? <ProblemSolving step={step} setStep={setStep} problemSolving={problemSolving} setProblemSolving={setProblemSolving} /> :
                                                                    step == 9 ? <TeamWork step={step} setStep={setStep} teamWork={teamWork} setTeamWork={setTeamWork} /> :
                                                                        step == 10 ? <CustomerService step={step} setStep={setStep} customerService={customerService} setCustomerService={setCustomerService} /> :
                                                                            step == 11 ? <Adaptibility step={step} setStep={setStep} adaptibility={adaptibility} setAdaptibility={setAdaptibility} /> :
                                                                                step == 12 ? <CompanyMotivation step={step} setStep={setStep} companyMotivation={companyMotivation} setCompanyMotivation={setCompanyMotivation} /> :
                                                                                    step == 13 ? <AdditionalQuestions step={step} setStep={setStep} additionalQuestions={additionalQuestions} setAdditionalQuestions={setAdditionalQuestions} /> : <></>
                                }
                            </div>
                            <div className="flex items-center justify-between p-4 border-t border-solid rounded-b border-blueGray-200">


                                <div className="flex gap-2">
                                    {step > 1 && (
                                        <button
                                            className="px-3 py-2 text-sm font-bold text-black bg-[#F8FAFB] hover:bg-[#FFF500] rounded transition-all duration-150 ease-linear outline-none focus:outline-none"
                                            type="button"
                                            onClick={() => setStep(prev => prev > 1 ? prev - 1 : 1)}
                                        >
                                            Previous
                                        </button>
                                    )}
                                    <button
                                        className="px-3 py-2 text-sm font-bold text-black bg-[#FFF500] rounded transition-all duration-150 ease-linear outline-none focus:outline-none"
                                        type="button"
                                    >
                                        Step {step}
                                    </button>
                                    {step < 13 && (
                                        <button
                                            className="px-3 py-2 text-sm font-bold text-black bg-[#F8FAFB] hover:bg-[#FFF500] rounded transition-all duration-150 ease-linear outline-none focus:outline-none"
                                            type="button"
                                            onClick={handleNextStep}
                                        >
                                            Next
                                        </button>
                                    )}

                                </div>
                                <button
                                    className={`px-20 py-4 mb-1 mr-1 text-sm font-bold text-black bg-[#FFF500] rounded uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none ${step < 13 ? 'opacity-50' : 'opacity-100'}`}
                                    type="button"
                                    onClick={() => {
                                        if (step === 13) {
                                            handleGenerateCV();
                                            setAddModal(true);
                                            setNewRecruiterModelStep(false);
                                            setQuestionaire(true);
                                        }
                                    }}
                                    disabled={step < 13}
                                >
                                    Complete
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const ContactDetails = ({ contactDetails, setContactDetails, error }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setContactDetails((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20'>
                <label className='text-lg font-semibold'>Contact Details</label>
                <span className={`${error ? 'block' : 'hidden'} text-red-500`}>{error}</span>
            </div>
            <div className='flex flex-col items-center justify-center w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col w-full gap-1'>
                    <label>Enter Your Complete Address *</label>
                    <input onChange={(e) => handleInputChange(e)} type='text' name='completeAddress' maxLength={55}  value={contactDetails.completeAddress} className='w-full p-2 bg-white border-none rounded shadow-md outline-none rounded-lgborder-gray-300' />
                </div>
                <div className='flex flex-row justify-between w-full gap-10'>
                    <div className='flex flex-col w-full gap-1'>
                        <label>City *</label>
                        <input onChange={(e) => handleInputChange(e)} type='text' name='city' maxLength={55}  value={contactDetails.city} className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' />
                    </div>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Postal Code *</label>
                        <input onChange={(e) => handleInputChange(e)} type='text' name='postalCode' maxLength={55}  value={contactDetails.postalCode} className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' />
                    </div>
                </div>
                <div className='flex flex-row justify-between w-full gap-10'>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Province (Optional)</label>
                        <input onChange={(e) => handleInputChange(e)} type='text' name='province' maxLength={55}  value={contactDetails.province} className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' />
                    </div>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Country *</label>
                        <input onChange={(e) => handleInputChange(e)} type='text' name='country' maxLength={55}  value={contactDetails.country} className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' />
                    </div>
                </div>
                <div className='flex flex-row justify-between w-full gap-10'>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Email *</label>
                        <input onChange={(e) => handleInputChange(e)} type='text' name='email' maxLength={55}  value={contactDetails.email} className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' />
                    </div>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Phone Number *</label>
                        <input onChange={(e) => handleInputChange(e)} type='text' name='phoneNumber' maxLength={55}  value={contactDetails.phoneNumber} className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' />
                    </div>
                </div>
                <div className='flex flex-row justify-between w-full gap-10'>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Landline Number (Optional) </label>
                        <input onChange={(e) => handleInputChange(e)} type='text' name='fixNumber' maxLength={55}  value={contactDetails.fixNumber} className='p-2 w-[47.5%] border-none bg-white shadow-md rounded-lg border-gray-300 rounded outline-none' />
                    </div>
                </div>
            </div>
        </div>
    )
}

const CareerGoals = ({ careerGoals, setCareerGoals }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCareerGoals((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [error, setError] = useState('');

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20'>
                <label className='text-lg font-semibold'>Career Goals</label>

            </div>
            <div className='flex flex-col items-center justify-center w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col w-full gap-1'>
                    <label>Target Job Title(s):</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='targetJob' maxLength={200} value={careerGoals.targetJob} className='block p-2 bg-white border-black border-none rounded-lg shadow-md' rows="4" placeholder='What specific roles are you interested in?' />
                </div>
                <div className='flex flex-col w-full gap-1'>
                    <label>Industry</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='industry' maxLength={200}  value={careerGoals.industry} className='block p-2 bg-white border-black border-none rounded-lg shadow-md' rows="4" placeholder='What industry are you targeting? (Optional)' />
                </div>
                <div className='flex flex-col w-full gap-1'>
                    <label>Ideal Work Environment</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='workEnvironment' maxLength={200}  value={careerGoals.workEnvironment} className='block p-2 bg-white border-black border-none rounded-lg shadow-md' rows="4" placeholder='What kind of company culture are you seeking? (e.g., fast-paced, collaborative)' />
                </div>
            </div>
        </div>
    )
}

const Education = ({ education, setEducation, error }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEducation((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20'>
                <label className='text-lg font-semibold'>Education</label>
                <span className={`${error ? 'block' : 'hidden'} text-red-500`}>{error}</span>
            </div>
            <div className='flex flex-col items-center justify-center w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col w-full gap-1'>
                    <p className='leading-10'>
                        <select
                            onChange={(e) => handleInputChange(e)}
                            name='degree'
                            value={education.degree}
                            className='p-2 mb-4 bg-white border-b border-gray-300 border-none rounded-lg shadow-md'
                        >
                            <option>Select Degree</option>
                            <option value="Elementary School">Elementary School</option>
                            <option value="High School">High School</option>
                            <option value="University/College">University/College</option>
                        </select>

                        <span className='mx-2'>in</span>
                        <input
                            onChange={(e) => handleInputChange(e)}
                            name='field'
                            value={education.field}
                            className='p-2 mx-2 mb-4 bg-white border-b border-gray-300 border-none rounded-lg shadow-md'
                            placeholder=' Field of Study *'
                        />

                        <input
                            onChange={(e) => handleInputChange(e)}
                            name='university'
                            value={education.university}
                            className='p-2 mx-2 mb-4 bg-white border-b border-gray-300 border-none rounded-lg shadow-md'
                            placeholder=' University Name *'
                        />,

                        <input
                            onChange={(e) => handleInputChange(e)}
                            name='place'
                            value={education.place}
                            className='p-2 mx-2 mb-4 bg-white border-b border-gray-300 border-none rounded-lg shadow-md'
                            placeholder=' City, State *'
                        />

                        Graduation Year:
                        <input
                            type='number'
                            onChange={(e) => handleInputChange(e)}
                            name='year'
                            value={education.year}
                            className='p-2 mx-2 mb-4 bg-white border-b border-gray-300 border-none rounded-lg shadow-md '
                            placeholder=' Year *'
                        />
                    </p>
                </div>

            </div>
            <div>
                <h className='text-xl font-semibold'>Experience and Skills</h>
                <div className='flex flex-col w-full gap-1 px-5 mt-3'>
                    <label>What specific experience do you have related to the [job position] you are applying for?</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='experience' maxLength={200}  value={education.experience} className='block p-2 border-none rounded-lg shadow-md border-b-' rows="3" placeholder='Write experience here...' />
                </div>
                <div className='flex flex-col w-full gap-1 px-5 mt-3'>
                    <label>Can you provide examples of projects or tasks where you demonstrated [relevant skills]?</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='projects' maxLength={200}  value={education.projects} className='block p-2 border-none rounded-lg shadow-md border-b-' rows="3" placeholder='Write skills and projects here...' />
                </div>
                <div className='flex flex-col w-full gap-1 px-5 mt-3'>
                    <label>How do you stay updated with industry trends and advancements?</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='information' maxLength={200}  value={education.information} className='block p-2 border-none rounded-lg shadow-md border-b-' rows="3" placeholder='Write your thoughts here...' />
                </div>
            </div>
            <div>
                <h className='text-xl font-semibold'>Job Specific Knowledge</h>
                <div className='flex flex-col w-full gap-1 px-5 mt-3'>
                    <label>What do you understand about the responsibilities and requirements of this role?</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='responsibilities' maxLength={200}  value={education.responsibilities} className='block p-2 border-none rounded-lg shadow-md border-b-' rows="3" placeholder='Responsibilities and Requirements...' />
                </div>
                <div className='flex flex-col w-full gap-1 px-5 mt-3'>
                    <label>How familiar are you with [specific tools/software] used in this industry?</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='toolAndSoftwares' maxLength={200}  value={education.toolAndSoftwares} className='block p-2 border-none rounded-lg shadow-md border-b-' rows="3" placeholder='' />
                </div>
                <div className='flex flex-col w-full gap-1 px-5 mt-3'>
                    <label>Can you explain your approach to [key aspect of the job]?</label>
                    <textarea onChange={(e) => handleInputChange(e)} name='approach' maxLength={200}  value={education.approach} className='block p-2 border-none rounded-lg shadow-md border-b-' rows="3" placeholder='Write your approach here...' />
                </div>
            </div>
        </div>
    )
}

const WorkExperience = ({ workExperiences, setWorkExperiences }) => {

    const [workExperience, setWorkExperience] = useState({
        company: '',
        location: '',
        title: '',
        startDate: null,
        endDate: null,
        achievements: '',
        currentlyWorking: false,
    })

    const [error, setError] = useState('');

    const handleAddExp = () => {
        if (!workExperience.company || !workExperience.location || !workExperience.title || workExperience.startDate == null || !workExperience.achievements) {
            setError('Please fill all the fields to add a work experience!')
        } else {
            setWorkExperiences(prevExperiences => [...prevExperiences, workExperience]);
            setError('');
            setWorkExperience({
                company: '',
                location: '',
                title: '',
                startDate: null,
                endDate: null,
                achievements: '',
                currentlyWorking: false,
            });
            setCurrentlyWorking(false)
            setEndDate(false)
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWorkExperience((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [endDate, setEndDate] = useState(false);
    const [currentlyWorking, setCurrentlyWorking] = useState(false);

    const handleRemoveWork = (indexToRemove) => {
        setWorkExperiences((prevExperiences) =>
            prevExperiences.filter((_, index) => index !== indexToRemove)
        );
    }

    console.log(workExperiences)
    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20'>
                <label className='text-lg font-semibold'>Work Experience</label>

            </div>
            <div className='flex flex-col items-center justify-center w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col w-full gap-4'>
                    {workExperiences.map((workExp, index) => (
                        <div key={index} className='relative flex flex-col w-full p-4 bg-white border-gray-200 border-none rounded-lg shadow-md'>
                            <div onClick={() => handleRemoveWork(index)} className='absolute p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer -top-2 -right-2 min-w-7 min-h-7 hover:bg-red-300'>
                                <img src={RedCross} className='w-5 h-5' />
                            </div>
                            <div className='flex flex-row justify-between gap-10 mb-2'>
                                <div className='flex flex-col'>
                                    <span className='text-xl font-semibold'>Company</span>
                                    <span>{workExp.company}</span>
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-xl font-semibold'>Job Title</span>
                                    <span>{workExp.title}</span>
                                </div>

                                <div className='flex flex-col'>
                                    <span className='text-xl font-semibold'>Location</span>
                                    <span>{workExp.location}</span>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                <div className='flex flex-row justify-between w-full gap-10'>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Company</label>
                        <input onChange={(e) => handleInputChange(e)} name='company' value={workExperience.company} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' />
                    </div>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Location</label>
                        <input onChange={(e) => handleInputChange(e)} name='location' value={workExperience.location} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' />
                    </div>
                </div>
                <div className='flex flex-row justify-between w-full gap-5'>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Job Title</label>
                        <input onChange={(e) => handleInputChange(e)} name='title' value={workExperience.title} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' />
                    </div>
                    <div className='flex flex-col w-full gap-1'>
                        <label>Start Date</label>
                        <input onChange={(e) => handleInputChange(e)} name='startDate' value={workExperience.startDate} type='date' className='p-2 border-b-2 border-gray-300 rounded outline-none' />
                    </div>
                    <div className={`flex-col w-full gap-1 ${currentlyWorking || endDate ? 'hidden' : 'flex'}`}>
                        <label>Currently working there?</label>
                        <div className='flex flex-row gap-2'>
                            <button onClick={() => {
                                setCurrentlyWorking(true)
                                setWorkExperience((prevData) => ({
                                    ...prevData,
                                    currentlyWorking: true,
                                }));
                            }} className='p-2 bg-green-500 border-gray-300 rounded outline-none'>Yes</button>
                            <button onClick={() => { setEndDate(true) }} className='p-2 bg-red-500 border-gray-300 rounded outline-none'>No</button>
                        </div>
                    </div>
                    <div className={`flex-col w-full gap-1 ${currentlyWorking ? 'flex' : 'hidden'}`}>
                        <label>Currently Working</label>
                    </div>

                    <div className={`flex-col w-full gap-1 ${(!currentlyWorking && endDate) ? 'flex' : 'hidden'}`}>
                        <label>End Date</label>
                        <input onChange={(e) => handleInputChange(e)} name='endDate' value={workExperience.endDate} type='date' className='p-2 border-b-2 border-gray-300 rounded outline-none' />
                    </div>
                </div>
            </div>
            <div>
                <h className='text-xl font-semibold'>Achievements and Contributions</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='achievements' maxLength={100}  value={workExperience.achievements} className='block p-2 border-black border-b-' rows="5" placeholder='Write 3-5 points...' />
                </div>
            </div>
            <button onClick={() => handleAddExp()} className='p-2 px-5 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded'>Add</button>
        </div>
    )
}

const Skills = ({ skills, setSkills, skillsInfo, setSkillsInfo }) => {

    const [skill, setSkill] = useState("");
    const [error, setError] = useState('');
    const handleAddSkill = () => {
        if (!skill) {
            setError('Please enter a skill to add!')
        } else {
            setSkills(prevSkills => [...prevSkills, skill]);
            setError('');
            setSkill("");
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSkillsInfo((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRemoveSkill = (indexToRemove) => {
        setSkills((prevSkills) =>
            prevSkills.filter((_, index) => index !== indexToRemove)
        );
    }

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20'>
                <label className='text-xl font-semibold'>Skills</label>

            </div>
            <div className='flex flex-col w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-auto gap-3'>
                    {skills.map((skill, index) => (
                        <div key={index} className='flex flex-row items-center gap-5 p-2 align-middle border-gray-300 border-none rounded-lg shadow-md max-w-fit min-w-fit'>
                            <span className='max-w-fit min-w-fit'>{skill}</span>
                            <div onClick={() => handleRemoveSkill(index)} className='p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer min-w-5 min-h-5 hover:bg-red-300'>
                                <img src={RedCross} className='w-4 h-4' />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-row gap-5'>
                    <input onChange={(e) => setSkill(e.target.value)} value={skill} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Write Skill...' />
                    <button onClick={() => handleAddSkill()} className='p-2 px-5  font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded'>Add</button>
                </div>
            </div>
            <div>
                <h className='text-xl font-semibold '>What technical skills do you possess</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='technicalSkills' maxLength={200}  value={skillsInfo.technicalSkills} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="5" placeholder='(e.g., programming languages, software proficiency)' />
                </div>
            </div>
            <div>
                <h className='text-xl font-semibold'>What soft skills do you excel in ?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='softSkills' maxLength={200}  value={skillsInfo.softSkills} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="5" placeholder='(e.g., communication, leadership, teamwork)' />
                </div>
            </div>
            <div>
                <h className='text-xl font-semibold'>Are there any specialized skills relevant to the job you're applying for?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='specialSkills' maxLength={200}  value={skillsInfo.specialSkills} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="5" placeholder='Write the Skills here...' />
                </div>
            </div>
        </div>
    )
}

const CertificationsAndLanguages = ({ certificates, setCertificates, languages, setLanguages, memberships, setMemberships, volunteerExperinces, setVolunteerExperinces, error }) => {

    const [certificate, setCertificate] = useState({
        name: '',
        organization: '',
        year: 2000
    });
    const [language, setLanguage] = useState({
        name: '',
        proficiency: 0
    });
    const [membership, setMembership] = useState({
        name: '',
        organization: ''
    });
    const [volunteerExperince, setVolunteerExperince] = useState({
        role: '',
        organization: '',
        startDate: null,
        endDate: null,
        description: ''
    });

    const handleAddCertificate = () => {
        console.log("adding skill", certificate);
        // Update the skills state immutably
        setCertificates(prevCertificates => [...prevCertificates, certificate]);
        setCertificate({
            name: '',
            organization: '',
            year: 200
        });
    }

    const handleAddLanguage = () => {
        console.log("adding skill", language);
        // Update the skills state immutably
        if (language.name && language.proficiency) {
            setLanguages(prevLanguages => [...prevLanguages, language]);
            setLanguage({
                name: '',
                proficiency: 0,
            });
        } else {
            toast.error('Select language and proficiency first!')
        }
    }

    const handleAddMembership = () => {
        console.log("adding skill", membership);
        // Update the skills state immutably
        setMemberships(prevMembership => [...prevMembership, membership]);
        setLanguage({
            name: '',
            organization: '',
        });
    }

    const handleAddVolunteerExperience = () => {
        console.log("adding skill", volunteerExperince);
        // Update the skills state immutably
        setVolunteerExperinces(prevExperiences => [...prevExperiences, volunteerExperince]);
        setVolunteerExperince({
            role: '',
            organization: '',
            startDate: null,
            endDate: null,
            description: ''
        });
    }

    const handleCertificateChange = (e) => {
        const { name, value } = e.target;
        setCertificate((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleLanguagesChange = (e) => {
        const { name, value } = e.target;
        setLanguage((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleMembershipChange = (e) => {
        const { name, value } = e.target;
        setMembership((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleVolunteerChange = (e) => {
        const { name, value } = e.target;
        setVolunteerExperince((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRemoveCertificate = (indexToRemove) => {
        setCertificates((prevCertificates) =>
            prevCertificates.filter((_, index) => index !== indexToRemove)
        );
    }
    const handleRemoveLan = (indexToRemove) => {
        setLanguages((prevLanguages) =>
            prevLanguages.filter((_, index) => index !== indexToRemove)
        );
    }
    const handleRemoveMemberShip = (indexToRemove) => {
        setMemberships((prevMemberships) =>
            prevMemberships.filter((_, index) => index !== indexToRemove)
        );
    }
    const handleRemoveVolunteer = (indexToRemove) => {
        setVolunteerExperinces((prevVolunteerExperinces) =>
            prevVolunteerExperinces.filter((_, index) => index !== indexToRemove)
        );
    }

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20'>
                <label className='text-xl font-semibold'>Certifications and Languages</label>
                <span className={`${error ? 'block' : 'hidden'} text-red-500`}>{error}</span>
            </div>
            <div className='flex flex-col w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col gap-3'>
                    <h className='text-lg font-semibold'>Certifications</h>
                    {certificates.map((certificate, index) => (
                        <div key={index} className='flex flex-row justify-between w-full gap-5 p-2 bg-white border-gray-300 rounded-lg shadow-md min-w-fit'>
                            <div className='flex flex-col max-w-fit min-w-fit'>
                                <span className='text-sm font-bold'>Certificate Name</span>
                                <span>{certificate.name}</span>
                            </div>
                            <div className='flex flex-col max-w-fit min-w-fit'>
                                <span className='text-sm font-bold'>Certificate Organization</span>
                                <span>{certificate.organization}</span>
                            </div>
                            <div className='flex flex-col max-w-fit min-w-fit'>
                                <span className='text-sm font-bold'>Certificate Year</span>
                                <span>{certificate.year}</span>
                            </div>
                            <div onClick={() => handleRemoveCertificate(index)} className='p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer min-w-5 min-h-5 max-h-7 hover:bg-red-300'>
                                <img src={RedCross} className='w-4 h-4' />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-row gap-5'>
                    <input onChange={(e) => handleCertificateChange(e)} name='name' value={certificate.name} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Certificate Name' />
                    <input onChange={(e) => handleCertificateChange(e)} name='organization' value={certificate.organization} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Organization' />
                    <input onChange={(e) => handleCertificateChange(e)} name='year' value={certificate.year} type='number' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Year' />
                    <button onClick={() => handleAddCertificate()} className='p-2 px-5 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded '>Add</button>
                </div>
            </div>
            <div className='flex flex-col w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col gap-3'>
                    <h className='text-lg font-semibold'>Languages</h>
                    {languages.map((language, index) => (
                        <div key={index} className='flex flex-row justify-between w-full gap-5 p-2 bg-white border-gray-300 border-none rounded-lg shadow-md min-w-fit'>
                            <span className='max-w-fit min-w-fit'>{language.name}</span>
                            <span className='max-w-fit min-w-fit'>{language.proficiency}</span>
                            <div onClick={() => handleRemoveLan(index)} className='p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer min-w-5 min-h-5 hover:bg-red-300'>
                                <img src={RedCross} className='w-4 h-4' />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-row gap-5'>
                    <select onChange={(e) => handleLanguagesChange(e)} name='name' value={language.name} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Select a language *' >
                        <option value={0}>Select Language *</option>
                        {LanguagesData.map((item, index) => (
                            <option key={index} value={item.language}>
                                {item.language}
                            </option>
                        ))}
                    </select>
                    <select onChange={(e) => handleLanguagesChange(e)} name='proficiency' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Expert'>
                        <option value={0}>Langaguge Level *</option>
                        <option value={"A1/A2"}>A1/A2</option>
                        <option value={"B1/B2"}>B1/B2</option>
                        <option value={"C1/C2"}>C1/C2</option>
                        <option value={"native"}>Native</option>
                    </select>
                    <button onClick={() => handleAddLanguage()} className='p-2 px-5 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded'>Add</button>
                </div>
            </div>
            <div className='flex flex-col w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col gap-3'>
                    <h className='text-lg font-semibold'>Professional Memberships</h>
                    {memberships.map((membership, index) => (
                        <div key={index} className='flex flex-row justify-between w-full gap-5 p-2 bg-white border-gray-300 border-none rounded-lg shadow-md min-w-fit'>
                            <span className='max-w-fit min-w-fit'>{membership.name}</span>
                            <span className='max-w-fit min-w-fit'>{membership.organization}</span>
                            <div onClick={() => handleRemoveMemberShip(index)} className='p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer min-w-5 min-h-5 hover:bg-red-300'>
                                <img src={RedCross} className='w-4 h-4' />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-row gap-5'>
                    <input onChange={(e) => handleMembershipChange(e)} name='name' value={membership.name} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Membership Name' />
                    <input onChange={(e) => handleMembershipChange(e)} name='organization' value={membership.organization} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Organization' />
                    <button onClick={() => handleAddMembership()} className='p-2 px-5 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded'>Add</button>
                </div>
            </div>
            <div className='flex flex-col w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col gap-3'>
                    <h className='text-lg font-semibold'>Volunteer Experience</h>
                    {volunteerExperinces.map((volunteerExperince, index) => (
                        <div key={index} className='relative flex flex-col justify-between w-full gap-3 p-2 bg-white border-gray-300 border-none rounded-lg shadow-md min-w-fit'>
                            <div onClick={() => handleRemoveVolunteer(index)} className='absolute p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer -top-2 -right-2 min-w-5 min-h-5 hover:bg-red-300'>
                                <img src={RedCross} className='w-4 h-4' />
                            </div>
                            <div className='flex flex-row justify-between w-full gap-5 p-2 min-w-fit'>
                                <div className='flex flex-col max-w-fit min-w-fit'>
                                    <span className='text-sm font-bold'>Role</span>
                                    <span>{volunteerExperince.role}</span>
                                </div>
                                <div className='flex flex-col max-w-fit min-w-fit'>
                                    <span className='text-sm font-bold'>Organization</span>
                                    <span>{volunteerExperince.organization}</span>
                                </div>
                                <div className='flex flex-col max-w-fit min-w-fit'>
                                    <span className='text-sm font-bold'>Start Date</span>
                                    <span>{volunteerExperince.startDate}</span>
                                </div>
                                <div className='flex flex-col max-w-fit min-w-fit'>
                                    <span className='text-sm font-bold'>End Date</span>
                                    <span>{volunteerExperince.endDate}</span>
                                </div>
                            </div>
                            <span className='text-sm font-bold'>Description</span>
                            <p>{volunteerExperince.description}</p>
                        </div>
                    ))}
                </div>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-row w-full gap-3'>
                        <input onChange={(e) => handleVolunteerChange(e)} name='role' value={volunteerExperince.name} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Volunteer Role' />
                        <input onChange={(e) => handleVolunteerChange(e)} name='organization' value={volunteerExperince.organization} type='text' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Organization' />
                        <input onChange={(e) => handleVolunteerChange(e)} name='startDate' value={volunteerExperince.startDate} type='date' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='Start Date' />
                        <input onChange={(e) => handleVolunteerChange(e)} name='endDate' value={volunteerExperince.endDate} type='date' className='p-2 border-b-2 border-gray-300 rounded outline-none' placeholder='End Date' />
                    </div>
                    <div className='flex flex-col w-full gap-1'>
                        <textarea onChange={(e) => handleVolunteerChange(e)} name='description' value={volunteerExperince.description} className='block p-2 border-black' rows="3" placeholder='Description of duties and contributions...' />
                    </div>
                    <button onClick={() => handleAddVolunteerExperience()} className='p-2 px-5 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded '>Add</button>
                </div>
            </div>
        </div>
    )
}

const AchievementsAndReferences = ({ achievements, setAchievements, references, setReferences }) => {

    const [achievement, setAchievement] = useState({
        name: '',
        description: '',
    })
    const [reference, setReference] = useState({
        name: '',
        description: '',
    })

    const handleAddAchievement = () => {
        console.log("adding skill", achievement);
        // Update the skills state immutably
        setAchievements(prevAchievements => [...prevAchievements, achievement]);
        setAchievement({
            name: '',
            description: '',
        });
    }

    const handleAchievementChange = (e) => {
        const { name, value } = e.target;
        setAchievement((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRemoveAchievement = (indexToRemove) => {
        setAchievements((prevAchievements) =>
            prevAchievements.filter((_, index) => index !== indexToRemove)
        );
    }

    const handleRemoveRef = (indexToRemove) => {
        setReferences((prevReferences) =>
            prevReferences.filter((_, index) => index !== indexToRemove)
        );
    }

    const handleAddReference = () => {
        console.log("adding skill", reference);
        // Update the skills state immutably
        setReferences(prevReferences => [...prevReferences, reference]);
        setReference({
            name: '',
            description: '',
        });
    }

    const handleReferenceChange = (e) => {
        const { name, value } = e.target;
        setReference((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20'>
                <label className='text-xl font-semibold'>Achievements and References</label>

            </div>
            <div className='flex flex-col w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col gap-3'>
                    <h className='text-lg font-semibold'>Achievements</h>
                    {achievements.map((achievement, index) => (
                        <div key={index} className='flex flex-row justify-between w-full gap-5 p-2 bg-white border-gray-300 border-none rounded-lg shadow-md min-w-fit'>
                            <div className='flex flex-row gap-5'>
                                <div className='flex flex-col max-w-fit min-w-fit'>
                                    <span className='text-sm font-bold'>Achievement Name</span>
                                    <span>{achievement.name}</span>
                                </div>
                                <div className='flex flex-col max-w-fit min-w-fit'>
                                    <span className='text-sm font-bold'>Achievement Description</span>
                                    <span>{achievement.description}</span>
                                </div>
                            </div>
                            <div onClick={() => handleRemoveAchievement(index)} className='p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer min-w-5 min-h-5 max-h-7 hover:bg-red-300'>
                                <img src={RedCross} className='w-4 h-4' />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-row gap-5'>
                    <input onChange={(e) => handleAchievementChange(e)} name='name' value={achievement.name} type='text' className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' placeholder='Achievement Name' />
                    <textarea onChange={(e) => handleAchievementChange(e)} name='description' value={achievement.description} type='text' className='w-full p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' placeholder='Description...' />
                    <button onClick={() => handleAddAchievement()} className='p-2 px-5 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded '>Add</button>
                </div>
            </div>
            <div className='flex flex-col w-full h-full gap-5 mt-10 align-middle'>
                <div className='flex flex-col gap-3'>
                    <h className='text-lg font-semibold '>References</h>
                    {references.map((reference, index) => (
                        <div key={index} className='flex flex-row justify-between w-full gap-5 p-2 border-2 border-gray-300 min-w-fit'>
                            <div className='flex flex-row gap-5'>
                                <span className='w-[250px]'>{reference.name}</span>
                                <span className='max-w-fit min-w-fit'>{reference.description}</span>
                            </div>
                            <div onClick={() => handleRemoveRef(index)} className='p-1 transition-all duration-300 border-2 border-red-500 rounded-full cursor-pointer min-w-5 min-h-5 hover:bg-red-300'>
                                <img src={RedCross} className='w-4 h-4' />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex flex-row gap-5'>
                    <input onChange={(e) => handleReferenceChange(e)} name='name' value={reference.name} type='text' className='p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' placeholder='Reference' />
                    <textarea onChange={(e) => handleReferenceChange(e)} name='description' value={reference.description} type='text' className='w-full p-2 bg-white border-gray-300 border-none rounded rounded-lg shadow-md outline-none' placeholder='Brief description and impact...' />
                    <button onClick={() => handleAddReference()} className='p-2 px-5 font-semibold text-white bg-[#CA9731] rounded-lg border-none border-gray-200 shadow-md rounded '>Add</button>
                </div>
            </div>
        </div>
    )
}

const ProblemSolving = ({ problemSolving, setProblemSolving }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProblemSolving((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20 mb-5'>
                <label className='text-xl font-semibold'>Problem Solving And Decision Making</label>

            </div>
            <div>
                <h className='font-semibold'>Describe a challenging situation you encountered at work and how you resolved it.</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='challengingSituation' maxLength={200}  value={problemSolving.challengingSituation} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>How do you prioritize tasks and manage deadlines effectively?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='deadlines' maxLength={200}  value={problemSolving.deadlines} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>What steps do you take to ensure quality and accuracy in your work?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='qualityWork' maxLength={200}  value={problemSolving.qualityWork} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
        </div>
    )
}

const TeamWork = ({ teamWork, setTeamWork }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTeamWork((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20 mb-5'>
                <label className='text-xl font-semibold'>Teamwork and Communication</label>

            </div>
            <div>
                <h className='font-semibold'>Give an example of a successful team project you were part of. What was your role, and how did you contribute?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='teamProject' maxLength={200}  value={teamWork.teamProject} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>How do you handle conflicts or disagreements within a team environment?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='handleConflicts'maxLength={200}  value={teamWork.handleConflicts} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Describe your communication style and how you adapt it based on different stakeholders.</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='communicationStyle' maxLength={200} value={teamWork.communicationStyle} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
        </div>
    )
}

const CustomerService = ({ customerService, setCustomerService }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerService((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20 mb-5'>
                <label className='text-xl font-semibold'>Customer Service and Client Interaction</label>

            </div>
            <div>
                <h className='font-semibold'>How do you approach customer/client satisfaction in your work?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='customerSatisfaction' maxLength={200}  value={customerService.customerSatisfaction} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Can you share an experience where you successfully addressed a customer's concern or request?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='experience' maxLength={200}  value={customerService.experience} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>What strategies do you use to build and maintain positive relationships with clients?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='strategies' maxLength={200}  value={customerService.strategies} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
        </div>
    )
}

const Adaptibility = ({ adaptibility, setAdaptibility }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdaptibility((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20 mb-5'>
                <label className='text-xl font-semibold'>Adaptibility and Learning</label>

            </div>
            <div>
                <h className='font-semibold'>How do you adapt to changes or unexpected challenges in your work environment?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='adaptToChanges' maxLength={200}  value={adaptibility.adaptToChanges} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Describe a situation where you had to learn a new skill or technology quickly. How did you approach it?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='learningSituation' maxLength={200}  value={adaptibility.learningSituation} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>What are your career goals, and how do you plan to achieve them?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='careerGoals' maxLength={200}  value={adaptibility.careerGoals} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
        </div>
    )
}

const CompanyMotivation = ({ companyMotivation, setCompanyMotivation }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompanyMotivation((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20 mb-5'>
                <label className='text-xl font-semibold'>Company Fit and Motivation</label>

            </div>
            <div>
                <h className='font-semibold'>What interests you most about working for our company?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='interests' maxLength={200}  value={companyMotivation.interests} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>How do you see yourself contributing to our team and achieving our company's goals?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='contribution' maxLength={200}  value={companyMotivation.contribution} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Why do you think you are the right fit for this position?</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='fitForPosition' maxLength={200}  value={companyMotivation.fitForPosition} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='' />
                </div>
            </div>
        </div>
    )
}
const AdditionalQuestions = ({ additionalQuestions, setAdditionalQuestions }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdditionalQuestions((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div className='flex flex-col gap-3 px-20 font-normal text-black w-fit bg-[#F8FAFB] py-10 pb-20 min-h-[70vh] max-h-[70vh] min-w-[60vw] max-w-[60vw] overflow-auto'>
            <div className='flex flex-row justify-between gap-20 mb-5'>
                <label className='text-xl font-semibold'>Additional Questions</label>

            </div>
            <div>
                <h className='font-semibold'>Software Proficiency: </h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='softwareProficiency' maxLength={200}  value={additionalQuestions.softwareProficiency} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='Have you received any awards, scholarships, or recognition for your work or achievements?' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Problem Solving</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='problemSolving' maxLength={200}  value={additionalQuestions.problemSolving} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='Describe a complex problem you faced at work and the steps you took to solve it.' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Adaptability & Initiative</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='adaptabilityAndInitiative' maxLength={200}  value={additionalQuestions.adaptabilityAndInitiative} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='Provide an example of when you quickly learned a new skill or adapted to a changing work environment.' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Continuous Learning</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='continuousLearning' maxLength={200}  value={additionalQuestions.continuousLearning} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='Do you have a passion for continuous learning? List any recent courses, workshops, or certifications you`ve completed' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Core Strengths</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='coreStrengths' maxLength={200}  value={additionalQuestions.coreStrengths} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='What are your top 3-5 skills or qualities that make you a valuable asset?' />
                </div>
            </div>
            <div>
                <h className='font-semibold'>Achievements Showcase</h>
                <div className='flex flex-col w-full gap-1'>
                    <textarea onChange={(e) => handleInputChange(e)} name='achievements' maxLength={200}  value={additionalQuestions.achievements} className='block p-2 bg-white border-none rounded-lg shadow-md' rows="4" placeholder='Think of 2-3 past projects or accomplishments from different roles that demonstrate your skills and impact.' />
                </div>
            </div>

        </div>
    )
}


export default Modal;



