import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cross from '../Resources/Icons/x.png';
import { toast } from 'react-toastify';
import Modal from './CandidatesList';
import { useNavigate } from 'react-router-dom';

const TransferModal = ({ transferModal, setTransferModal, jobId, recruiterId, onTransfer }) => {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const [job, setJob] = useState({
        company: '',
        category: '',
        position: '',
        location: '',
        period: '',
        qualification: '',
        amenities: '',
        permit: '',
        overtime: '',
        vacancies: 0,
    });
    const [recruiter, setRecruiter] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        status: 'Inactive',
        coverLetter: '',
        dateEdited: Date.now()
    });
    const [recruiters, setRecruiters] = useState([]);
    const [filteredRecruiters, setFilteredRecruiters] = useState([]);

    const fetchRecruiters = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recruiter/getallrecruiters`);
            const activeRecruiters = response.data.filter((recruiter) => recruiter.status === 'Active');
            setRecruiters(activeRecruiters);
        } catch (error) {
            console.error('Error fetching recruiters: ', error.message);
        }
    }

    const fetchJob = async (jobId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/job/getjob/${jobId}`);
            setJob(response.data);
        } catch (error) {
            console.error('Error fetching job: ', error.message);
        }
    }

    useEffect(() => {
        fetchRecruiters();
        if (jobId) {
            fetchJob(jobId);
        }
    }, [jobId]);

    useEffect(() => {
        if (job.position) {
            const filtered = recruiters.filter((recruiter) => recruiter.position === job.position);
            setFilteredRecruiters(filtered);
        }
    }, [job.position, recruiters]);

    const handleTransfer = async () => {
        try {
            const mail = sessionStorage.getItem('user');
            const role = 'admin';
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/job/transferJob`, { job, recruiter, mail, role });
            if (response.status === 200) {
                setTransferModal(false);
                onTransfer(response.data.job);
                window.location.reload();
                navigate('/index'); // Redirect to index page
            }
        } catch (error) {
            console.error('Error transferring the job: ', error.message);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setJob((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleOnClickRecruiter = async (recruiterId) => {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/recruiter/getrecruiter/${recruiterId}`);
        setRecruiter(response.data);
    }

    const resetRecruiter = () => {
        setRecruiter({
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            status: 'Inactive',
            coverLetter: '',
            dateEdited: Date.now()
        });
    };

    return (
        <>
            {transferModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="fixed inset-0 bg-black opacity-50"></div>
                    <div className="relative w-auto mx-auto my-6">
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            <div className="flex items-center justify-between gap-20 p-5 px-10 border-b border-solid rounded-t border-blueGray-200">
                                <img src={Cross} className='w-10 h-10 transition-all duration-200 cursor-pointer hover:scale-110' onClick={() => { setTransferModal(false); resetRecruiter(); }} />
                                <h3 className="text-xl font-bold text-black">Transfer</h3>
                            </div>
                            <div className='px-10 pt-5 bg-[#F8FAFB]'>
                                <div className='w-[150px] h-[150px] bg-gray-300 rounded-full'></div>
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 md:gap-28 px-10 font-normal text-black w-fit py-5 pb-10 bg-[#F8FAFB]'>
                                <div className='flex flex-col gap-5'>
                                    <div className='flex flex-col gap-3'>
                                        <button onClick={() => { setShowModal(true); }} className='flex flex-col justify-end gap-2 w-[300px] text-right border-2 border-gray-300 rounded p-2 hover:bg-gray-300 transition-all duration-300'>
                                            Select Candidate
                                        </button>
                                        <Modal showModal={showModal} setShowModal={setShowModal} setRecruiter={setRecruiter} recruiters={filteredRecruiters} />
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Name</label>
                                            <input readOnly type='text' name='name' value={recruiter.firstName} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='Enter Your Email ID' />
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Email ID</label>
                                            <input readOnly type='text' name='email' value={recruiter.email} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='Enter Your Email ID' />
                                        </div>
                                        <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                            <label>Phone No</label>
                                            <input readOnly type='text' name='phone' value={recruiter.phone} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='Enter Phone' />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Company</label>
                                        <input readOnly type='text' name='company' value={job.company} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Job Category</label>
                                        <input readOnly type='text' name='jobCategory' value={job.category} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='Manufacturing and Production' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Job Position</label>
                                        <input readOnly type='text' name='jobPosition' value={job.position} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='Manager' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Job Location</label>
                                        <input readOnly type='text' name='jobLocation' value={job.location} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' placeholder='ALBANIA' />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Period of Contract</label>
                                        <input readOnly type='text' name='period' value={job.period} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Qualification</label>
                                        <input readOnly type='text' name='qualification' value={job.qualification} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Food and Accommodation</label>
                                        <input readOnly type='text' name='foodAndAcc' value={job.amenities} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Resident Permit</label>
                                        <input readOnly type='text' name='permit' value={job.permit} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                    <div className='flex flex-col justify-end gap-2 w-[300px] text-right'>
                                        <label>Overtime</label>
                                        <input readOnly type='text' name='overtime' value={job.overtime} className='w-full p-2 border-[2px] outline-gray-100 outline-[1px] border-gray-200 bg-white rounded' />
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-4 px-10 border-t border-solid rounded-b border-blueGray-200">
                                <button
                                    className="px-20 py-4 mb-1 mr-1 text-sm font-bold text-white bg-[#CA9731] bg-opacity-[75%] rounded-full uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                    type="button"
                                    onClick={() => {
                                        if (!job.company) {
                                            toast.error('Select the Company first.')
                                        } else if (!recruiter.email) {
                                            toast.error('Select the Candidate first.')
                                        } else {
                                            handleTransfer();
                                        }
                                    }}
                                >
                                    Transfer
                                </button>
                                <button
                                    className="px-28 py-4 mb-1 mr-1 text-sm font-bold text-gray-400 bg-[#F8FAFB] hover:bg-slate-300 rounded-full uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                    type="button"
                                    onClick={() => { setTransferModal(false); resetRecruiter(); }}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TransferModal;
